import { useEffect } from "react";
import { envEnums } from "../enums/enums";

function useUpdateDomTitle(title,themeColor){

    const defaultTitle = envEnums.IS_SCANNED_PAGE ? 'Scanned Page' : 'QR Code'
    useEffect(()=>{
        document.title = title ? title : defaultTitle;
        document.querySelector("meta[name=\"theme-color").content = themeColor ? themeColor : "#ffffff";
    })
}

export default useUpdateDomTitle;