import {React} from "react";
import "../index.css";
// import useLanguage from "../../../utils/useLanguage";
import useUpdateDomTitle from "../../../utils/updateDomTitle";



let InstagramPreviewV3 = (props) => {

  useUpdateDomTitle('Online QR Generator - Scanned Page | Instagram',"#ED3E7F");
  return (
    <div className="flex flex-col justify-center items-center relative">
      <div className="flex flex-col items-center justify-center mt-[50px] w-full max-w-[700px]">
        <div className="flex items-center justify-between w-full mb-5  px-[20px]">
          <div className="flex items-center justify-between space-x-[12px]">
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 1L0.999938 8.00006L8 15.0001" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div className="flex items-center justify-between space-x-[4px]">
              <p className="font-bold text-base xs:text-lg">stunningtravelphotography</p>
              {/* Verified badge */}
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3031_326)">
                  <path d="M5.56209 0.980486C5.67561 0.653806 6.07855 0.538265 6.34794 0.755147L7.57054 1.73945C7.75871 1.89095 8.02828 1.88624 8.21105 1.72827L9.39855 0.701898C9.66021 0.475747 10.0669 0.577156 10.1918 0.899675L10.7584 2.36341C10.8457 2.58869 11.0815 2.7194 11.3187 2.67398L12.8603 2.37887C13.2 2.31384 13.5015 2.60503 13.4484 2.94676L13.2073 4.49772C13.1701 4.73643 13.309 4.96753 13.5372 5.04683L15.0198 5.56205C15.3465 5.67557 15.462 6.07851 15.2452 6.3479L14.2609 7.5705C14.1094 7.75867 14.1141 8.02824 14.272 8.21101L15.2984 9.39851C15.5246 9.66017 15.4231 10.0669 15.1006 10.1917L13.6369 10.7584C13.4116 10.8456 13.2809 11.0814 13.3263 11.3187L13.6214 12.8603C13.6865 13.2 13.3953 13.5015 13.0535 13.4484L11.5026 13.2072C11.2639 13.1701 11.0328 13.309 10.9535 13.5372L10.4383 15.0198C10.3247 15.3465 9.92179 15.462 9.65241 15.2451L8.42981 14.2608C8.24164 14.1093 7.97206 14.114 7.78929 14.272L6.60179 15.2984C6.34014 15.5245 5.93341 15.4231 5.80856 15.1006L5.24191 13.6369C5.1547 13.4116 4.91889 13.2809 4.68162 13.3263L3.14002 13.6214C2.80035 13.6864 2.49882 13.3952 2.55195 13.0535L2.79308 11.5025C2.8302 11.2638 2.69134 11.0327 2.46315 10.9534L0.980527 10.4382C0.653848 10.3247 0.538307 9.92175 0.755189 9.65236L1.7395 8.42977C1.89099 8.2416 1.88628 7.97202 1.72832 7.78925L0.70194 6.60175C0.475788 6.3401 0.577197 5.93337 0.899716 5.80851L2.36345 5.24187C2.58873 5.15465 2.71945 4.91884 2.67402 4.68158L2.37891 3.13998C2.31388 2.80031 2.60507 2.49878 2.94681 2.55191L4.49776 2.79304C4.73647 2.83016 4.96757 2.69129 5.04687 2.4631L5.56209 0.980486Z" fill="#1FA1FF"/>
                  <path d="M5 8L7 10L11 6" stroke="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_3031_326">
                    <rect width="16" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            </div>
          </div>
          <div className="flex items-center justify-between space-x-2 xxs:space-x-[6px] xs:space-x-[10px] xsm:space-x-[14px] sm:space-x-[20px]">
            <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_3031_336" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="18">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0H18.4969V17.348H0V0Z" fill="white"/>
              </mask>
              <g mask="url(#mask0_3031_336)">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.24701 1.5C5.75201 1.5 3.31601 4.238 3.31601 6.695C3.31601 8.774 2.73901 9.735 2.22901 10.583C1.82001 11.264 1.49701 11.802 1.49701 12.971C1.66401 14.857 2.90901 15.848 9.24701 15.848C15.55 15.848 16.834 14.813 17 12.906C16.997 11.802 16.674 11.264 16.265 10.583C15.755 9.735 15.178 8.774 15.178 6.695C15.178 4.238 12.742 1.5 9.24701 1.5M9.24701 17.348C4.57101 17.348 0.345013 17.018 1.33547e-05 13.035C-0.00298665 11.387 0.500013 10.549 0.944013 9.811C1.39301 9.063 1.81601 8.358 1.81601 6.695C1.81601 3.462 4.80201 0 9.24701 0C13.692 0 16.678 3.462 16.678 6.695C16.678 8.358 17.101 9.063 17.55 9.811C17.994 10.549 18.497 11.387 18.497 12.971C18.148 17.018 13.923 17.348 9.24701 17.348" fill="black"/>
                </g>
              <path fillRule="evenodd" clipRule="evenodd" d="M9.19803 21.5001H9.19603C8.07503 21.4991 7.01403 21.0051 6.20903 20.1081C5.93203 19.8011 5.95703 19.3261 6.26503 19.0501C6.57303 18.7721 7.04703 18.7971 7.32403 19.1061C7.84203 19.6831 8.50703 20.0001 9.19703 20.0001H9.19803C9.89103 20.0001 10.559 19.6831 11.078 19.1051C11.356 18.7981 11.83 18.7731 12.137 19.0501C12.445 19.3271 12.47 19.8021 12.193 20.1091C11.385 21.0061 10.322 21.5001 9.19803 21.5001" fill="black"/>
            </svg>
            <svg width="14" height="3" viewBox="0 0 14 3" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="1.5" cy="1.5" r="1.5" fill="#7E7E7E"/>
              <circle cx="7" cy="1.5" r="1.5" fill="#7E7E7E"/>
              <circle cx="12.5" cy="1.5" r="1.5" fill="#7E7E7E"/>
            </svg>

          </div>
        </div>
        <div className="flex items-center justify-between w-full sm:pr-[10px] mb-5  px-[20px]">
          <img src="/images/images/new/v3/instagram_avatar.png" className="w-[50px] xxs:w-[70px] xs:w-[80px] sm:w-[120px]" alt="" />
          <div className="flex items-center justify-end w-full pl-1 xsm:pl-8 sm:pl-20 text-black">
            <div className="flex-1 px-1">
              <p className="font-bold mb-2 text-[10px] xxs:text-xs xs:text-sm sm:text-base">879</p>
              <p className="font-semibold text-[10px] xxs:text-xs xs:text-sm sm:text-base">Posts</p>
            </div>
            <div className="flex-1 px-1">
              <p className="font-bold mb-2 text-[10px] xxs:text-xs xs:text-sm sm:text-base">113k</p>
              <p className="font-semibold text-[10px] xxs:text-xs xs:text-sm sm:text-base">Followers</p>
            </div>
            <div className="flex-1 px-1">
              <p className="font-bold mb-2 text-[10px] xxs:text-xs xs:text-sm sm:text-base">2,160</p>
              <p className="font-semibold text-[10px] xxs:text-xs xs:text-sm sm:text-base">Following</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full text-start space-y-[3px] mb-3  px-[20px]">
          <p className="text-black font-bold ">Stunning Travel Photography</p>
          <p className="text-[#6E6E6E] font-normal text-[13px]">Travel and Photography Magazine</p>
          <p className="text-black text-[13px]">We provide the best travel photos available! Contact us to order prints, or visit our online store to view our entire inventory.</p>
          <p className="text-[#223B42] cursor-pointer text-[13px]">www.stunningtravel.com</p>
        </div>
        <div className="flex gap-[6px] w-full mb-5  px-[20px]">
          <div className="flex-1 flex gap-2">
            <div className="flex-1 bg-[#EFEFEF] rounded font-bold py-[7px] text-black text-[10px] xxs:text-xs xs:text-sm">Follow</div>
            <div className="flex-1 bg-[#EFEFEF] rounded font-bold py-[7px] text-black text-[10px] xxs:text-xs xs:text-sm">Message</div>
            <div className="flex-1 bg-[#EFEFEF] rounded font-bold py-[7px] text-black text-[10px] xxs:text-xs xs:text-sm">Contact</div>
          </div>
          <div className="bg-[#EFEFEF] rounded flex items-center justify-center p-2">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="14" height="14" transform="translate(0 -0.00146484)" fill="#EFEFEF"/>
              <circle cx="9" cy="3.99854" r="1.85" stroke="black" strokeWidth="1.3"/>
              <path d="M13 11.9985V10.9985C13 9.34168 11.6569 7.99854 10 7.99854H8C6.34315 7.99854 5 9.34168 5 10.9985V11.9985" stroke="black" strokeWidth="1.3"/>
              <line x1="3.525" y1="4.64854" x2="3.525" y2="8.34854" stroke="black" strokeWidth="1.3" stroke-linecap="round"/>
              <line x1="1.65" y1="6.44863" x2="5.35" y2="6.44863" stroke="black" strokeWidth="1.3" stroke-linecap="round"/>
            </svg>
          </div>
        </div>
        <div className="flex justify-between items-center w-full gap-[4px] xsm:gap-2 mb-4  px-[20px]">
          <div className="flex flex-col items-center truncate text-start">
            <img src="/images/images/new/v3/instagram/stories/japan.webp" className="xxs:w-[30px] xs:w-[60px] sm:w-full mb-1" alt="" />
            <p className="text-start text-[8px] xxs:text-[10px] xsm:text-sm sm:text-base truncate">Japan</p>
          </div>
          <div className="flex flex-col items-center truncate text-start">
            <img src="/images/images/new/v3/instagram/stories/italy.webp" className="xxs:w-[30px] xs:w-[60px] sm:w-full mb-1" alt="" />
            <p className="text-start text-[8px] xxs:text-[10px] xsm:text-sm sm:text-base truncate">Italy</p>
          </div>
          <div className="flex flex-col items-center truncate text-start">
            <img src="/images/images/new/v3/instagram/stories/greece.webp" className="xxs:w-[30px] xs:w-[60px] sm:w-full mb-1" alt="" />
            <p className="text-start text-[8px] xxs:text-[10px] xsm:text-sm sm:text-base truncate">Greece</p>
          </div>
          <div className="flex flex-col items-center truncate text-start">
            <img src="/images/images/new/v3/instagram/stories/guatemala.webp" className="xxs:w-[30px] xs:w-[60px] sm:w-full mb-1" alt="" />
            <p className="text-start text-[8px] xxs:text-[10px] xsm:text-sm sm:text-base truncate">Guatemala</p>
          </div>
          <div className="flex flex-col items-center truncate text-start">
            <img src="/images/images/new/v3/instagram/stories/usa.webp" className="xxs:w-[30px] xs:w-[60px] sm:w-full mb-1" alt="" />
            <p className="text-start text-[8px] xxs:text-[10px] xsm:text-sm sm:text-base truncate">USA</p>
          </div>
        </div>
        <div className="flex justify-center items-center w-full gap-[15px]">
          <div className="flex-1 flex items-center justify-center border-b-[2px] pt-[15px] pb-[14px] border-black cursor-pointer">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.44762 1V17M1.19048 11.5524L17.1905 11.5524M11.4 1V17M1.19048 6.6L17.1905 6.6M1 1H17V17H1V1Z" stroke="black" stroke-width="1.8" stroke-linejoin="round"/>
            </svg>
          </div>
          <div className="flex-1 flex items-center justify-center py-3 cursor-pointer"> 
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.24 14.3268C15.5867 14.5334 15.5867 15.0499 15.24 15.2565L9.78 18.5106C9.43333 18.7172 9 18.459 9 18.0457L9 11.5376C9 11.1244 9.43333 10.8661 9.78 11.0727L15.24 14.3268Z" fill="#C4C4C4"/>
              <path d="M6 2L10 8" stroke="#C4C4C4" stroke-width="1.8"/>
              <path d="M13 2L17 8" stroke="#C4C4C4" stroke-width="1.8"/>
              <rect x="1.9" y="1.9" width="20.2" height="20.2" rx="5.1" stroke="#C4C4C4" stroke-width="1.8"/>
              <path d="M2 8H22" stroke="#C4C4C4" stroke-width="1.8"/>
            </svg>
          </div>
          <div className="flex-1 flex items-center justify-center  py-3 cursor-pointer">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-inside-1_3391_358" fill="white">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2162 1.2248C12.0982 1.10215 11.9018 1.10215 11.7838 1.2248L9.11322 4.00017H3C1.89543 4.00017 1 4.8956 1 6.00017V21.0002C1 22.1047 1.89543 23.0002 3 23.0002H21C22.1046 23.0002 23 22.1047 23 21.0002V6.00017C23 4.8956 22.1046 4.00017 21 4.00017H14.8868L12.2162 1.2248Z"/>
              </mask>
              <path d="M11.7838 1.2248L10.4868 -0.0232763L10.4868 -0.0232755L11.7838 1.2248ZM12.2162 1.2248L13.5132 -0.0232755L13.5132 -0.0232763L12.2162 1.2248ZM9.11322 4.00017V5.80017C9.60262 5.80017 10.0709 5.6009 10.4103 5.24825L9.11322 4.00017ZM14.8868 4.00017L13.5897 5.24825C13.9291 5.6009 14.3974 5.80017 14.8868 5.80017V4.00017ZM13.0809 2.47288C12.4908 3.08612 11.5092 3.08612 10.9191 2.47288L13.5132 -0.0232763C12.6871 -0.881824 11.3129 -0.881825 10.4868 -0.0232763L13.0809 2.47288ZM10.4103 5.24825L13.0809 2.47288L10.4868 -0.0232755L7.81619 2.7521L10.4103 5.24825ZM3 5.80017H9.11322V2.20017H3V5.80017ZM2.8 6.00017C2.8 5.88971 2.88954 5.80017 3 5.80017V2.20017C0.901318 2.20017 -0.8 3.90149 -0.8 6.00017H2.8ZM2.8 21.0002V6.00017H-0.8V21.0002H2.8ZM3 21.2002C2.88954 21.2002 2.8 21.1106 2.8 21.0002H-0.8C-0.8 23.0989 0.901316 24.8002 3 24.8002V21.2002ZM21 21.2002H3V24.8002H21V21.2002ZM21.2 21.0002C21.2 21.1106 21.1105 21.2002 21 21.2002V24.8002C23.0987 24.8002 24.8 23.0989 24.8 21.0002H21.2ZM21.2 6.00017V21.0002H24.8V6.00017H21.2ZM21 5.80017C21.1105 5.80017 21.2 5.88971 21.2 6.00017H24.8C24.8 3.90149 23.0987 2.20017 21 2.20017V5.80017ZM14.8868 5.80017H21V2.20017H14.8868V5.80017ZM10.9191 2.47288L13.5897 5.24825L16.1838 2.7521L13.5132 -0.0232755L10.9191 2.47288Z" fill="#C4C4C4" mask="url(#path-1-inside-1_3391_358)"/>
                <circle cx="12" cy="12" r="3.1" stroke="#C4C4C4" stroke-width="1.8"/>
              <path d="M5 22V22C5 19.7909 6.79086 18 9 18H15C17.2091 18 19 19.7909 19 22V22" stroke="#C4C4C4" stroke-width="1.8"/>
            </svg>
          </div>
        </div>
        <div className="grid grid-cols-3 w-full gap-[1px] xs:gap-[2px] mb-14">
          <img className="w-full flex-1" src="/images/images/new/v3/instagram/1.webp" alt="" />
          <img className="w-full flex-1" src="/images/images/new/v3/instagram/2.webp" alt="" />
          <img className="w-full flex-1" src="/images/images/new/v3/instagram/3.webp" alt="" />
          <img className="w-full flex-1" src="/images/images/new/v3/instagram/4.webp" alt="" />
          <img className="w-full flex-1" src="/images/images/new/v3/instagram/5.webp" alt="" />
          <img className="w-full flex-1" src="/images/images/new/v3/instagram/6.webp" alt="" />
          <img className="w-full flex-1" src="/images/images/new/v3/instagram/7.webp" alt="" />
          <img className="w-full flex-1" src="/images/images/new/v3/instagram/8.webp" alt="" />
          <img className="w-full flex-1" src="/images/images/new/v3/instagram/9.webp" alt="" />
        </div>
        <div className="flex justify-around items-center w-full max-w-[700px] py-2 fixed bottom-0 bg-white">
          <div className="flex-1 flex items-center justify-center">
            <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M21.7 10.7045L11.7 0.903026C11.3111 0.521908 10.6889 0.521908 10.3 0.903026L0.300019 10.7045C0.108129 10.8925 0 11.1499 0 11.4186V23.4985L0.00804008 23.5884C0.0503849 23.8217 0.25454 23.9985 0.5 23.9985L8.5 23.9982C8.77613 23.9982 8.99998 23.7743 9 23.4982V17.9985L9.00509 17.8223C9.09634 16.2474 9.40232 14.9985 11 14.9985L11.1763 15.0036C12.6344 15.0881 12.9558 16.214 12.9955 17.6487L13 17.9985V23.4982C13 23.7743 13.2239 23.9982 13.5 23.9982L21.5 23.9985L21.5899 23.9905C21.8231 23.9481 22 23.744 22 23.4985V11.4186C22 11.1499 21.8919 10.8925 21.7 10.7045ZM1.5 11.6278L11 2.31641L20.5 11.6278V22.4976L14.5 22.4973V17.9976L14.4953 17.6283C14.4284 15.1982 13.5423 13.6373 11.263 13.5052L11.0433 13.4983C8.55145 13.4976 7.66187 15.0722 7.5076 17.7346L7.50063 17.9543L7.50011 22.4973L1.5 22.4976V11.6278Z" fill="#262626"/>
            </svg>
          </div>
          <div className="flex-1 flex items-center justify-center">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10.5" cy="10.0078" r="8.1" stroke="black" stroke-width="1.8"/>
              <path d="M22.5 22.0078L16.5 16.0078" stroke="black" stroke-width="1.8" stroke-linecap="round"/>
            </svg>
          </div>
          <div className="flex-1 flex items-center justify-center  py-[10px]">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9211 1.90255H8.07895C4.93964 1.90255 2.39474 4.44746 2.39474 7.58676V16.4289C2.39474 19.5682 4.93964 22.1131 8.07895 22.1131H16.9211C20.0604 22.1131 22.6053 19.5682 22.6053 16.4289V7.58676C22.6053 4.44746 20.0604 1.90255 16.9211 1.90255ZM8.07895 0.0078125C3.89321 0.0078125 0.5 3.40102 0.5 7.58676V16.4289C0.5 20.6146 3.89321 24.0078 8.07895 24.0078H16.9211C21.1068 24.0078 24.5 20.6146 24.5 16.4289V7.58676C24.5 3.40102 21.1068 0.0078125 16.9211 0.0078125H8.07895Z" fill="black"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 4.74465C13.0232 4.74465 13.4474 5.16881 13.4474 5.69202V11.0604H18.8158C19.339 11.0604 19.7632 11.4846 19.7632 12.0078C19.7632 12.531 19.339 12.9552 18.8158 12.9552H13.4474V18.3236C13.4474 18.8468 13.0232 19.271 12.5 19.271C11.9768 19.271 11.5526 18.8468 11.5526 18.3236V12.9552H6.18421C5.66099 12.9552 5.23684 12.531 5.23684 12.0078C5.23684 11.4846 5.66099 11.0604 6.18421 11.0604H11.5526V5.69202C11.5526 5.16881 11.9768 4.74465 12.5 4.74465Z" fill="black"/>
            </svg>
          </div>
          <div className="flex-1 flex items-center justify-center">
            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8725 2.65511L11.2175 1.88951C8.58142 -0.746533 4.37761 -0.5669 1.74157 2.06914C-0.894471 4.70518 -0.387159 9.80151 2.24888 12.4375C3.31874 13.5074 4.92092 14.9887 7.05542 16.8815L8.85115 18.4612L10.8681 20.212C11.4234 20.6913 12.2439 20.6986 12.8077 20.2293L14.4416 18.8594C17.3029 16.4413 19.4262 14.5366 20.8115 13.1454L21.171 12.7788L21.4939 12.4375C23.9489 9.79014 24.5798 4.64387 22.005 2.06914L21.8185 1.88951C19.1711 -0.565474 15.179 -0.685229 12.6042 1.88951L11.8725 2.65511ZM2.80223 3.1298C4.88666 1.04537 8.11137 0.947647 10.118 2.91183L11.8111 4.89048L13.6886 2.92588C15.6145 1.00055 18.6838 1.02838 20.7986 2.98939L20.9645 3.14958C22.7839 4.96937 22.4744 9.17411 20.394 11.4176L20.0815 11.7477L19.7348 12.1012C18.5864 13.2537 16.8845 14.7978 14.6405 16.7208L13.4734 17.7138L11.8481 19.0764L9.83442 17.3285L8.39319 16.0625C6.36239 14.2676 4.79575 12.832 3.69885 11.7613L3.30954 11.3769C1.138 9.20535 0.866398 5.06563 2.80223 3.1298Z" fill="#262626"/>
            </svg>
          </div>
          <div className="flex-1 flex items-center justify-center">
            <img src="/images/images/new/v3/footer_avatar.png" className="w-[25px] xxs:w-[26px] xs:w-[28px] sm:w-[30px] border border-black rounded-full" alt=""/>
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default InstagramPreviewV3;
