const envEnums = Object.freeze({
    SCANNED_PAGE:"sp",
    QCI:'qcc',
    IS_SCANNED_PAGE:process.env.REACT_APP_DOMAIN === "sp"
});

const qrStatusEnums = Object.freeze({
    Active:'1',
    Paused:'2',
    Deleted:'3',
    Blocked:'4',
    Draft:'5'
});

export {envEnums,qrStatusEnums};