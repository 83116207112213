import "./App.css";
import "./components/backdrop.css";
import { useEffect, React } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./components/Main";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import WebFont from "webfontloader";
import NoData from "./components/NoData";

import WebsitePreview from "./components/Preview/Website";
import PDFPreview from "./components/Preview/PDF";
import ImagesPreview from "./components/Preview/Images";
import VideoPreview from "./components/Preview/Video";
import WIFIPreview from "./components/Preview/Wifi";
import MenuPreview from "./components/Preview/Menu";
import VcardPreview from "./components/Preview/Vcard";
import Mp3Preview from "./components/Preview/Mp3";
import AppsPreview from "./components/Preview/Apps";
import ListOfLinksPreview from "./components/Preview/ListOfLinks";
import CouponPreview from "./components/Preview/Coupon";
import BusinessPreview from "./components/Preview/Business";
import EventPreview from "./components/Preview/Event";
import InstagramPreview from "./components/Preview/Instagram";
import InstagramPreviewV2 from "./components/v2/Instagram";
import InstagramPreviewV3 from "./components/Preview/InstagramV3";
import InstagramPreviewV4 from "./components/Preview/InstagramV4";
import FacebookPreview from "./components/Preview/Facebook";
import SocialMediaPreview from "./components/Preview/SocialMedia";
import ReviewPreview from "./components/Preview/Review";
import WhatsappPreview from "./components/Preview/Whatsapp";
import SMSPreview from "./components/Preview/SMS";
import EmailPreview from "./components/Preview/Email";
import SMS2Preview from "./components/Preview/SMS2";
import SMS3Preview from "./components/Preview/SMS3";
import SMS4Preview from "./components/Preview/SMS4";
import Whatsapp2Preview from "./components/Preview/Whatsapp2";
import Whatsapp4Preview from "./components/Preview/Whatsapp4";

import { SnackbarProvider } from "notistack";
import StepController from "./components/stepController";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import { getDeactivatePage, getDefaultPage, getRoutePath } from "./utils/utils";


const THEME = createTheme({
  typography: {
    fontFamily: `'Eudoxus Sans', sans-serif`,
    //  "fontSize": 14,
    //  "fontWeightLight": 300,
    //  "fontWeightRegular": 400,
    //  "fontWeightMedium": 500
  },
});

function App() {
  // const { t, i18n } = useTranslation();
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Noto Sans",
          "Concert One",
          "Lato",
          "Open Sans",
          "Roboto",
          "Oswald",
          "Montserrat",
          "Source Sans Pro",
          "Slabo 27px",
          "Raleway",
          "Merriweather",
          "Inconsolata",
          "Dancing Script",
        ],
      },
    });
  }, []);
  return (
    <ThemeProvider theme={THEME}>
      <div className="App" >
        <SnackbarProvider>
          <BrowserRouter>
            <Routes>
              <Route path={getRoutePath(true)} element={<Main />} />
              <Route path={getRoutePath()} element={<Main />} />
              <Route path="/" element={getDefaultPage()} />
              <Route path="/no-data" element={<NoData />} />
              <Route path="/deactivate" element={getDeactivatePage()} />

              <Route path="/vcard" element={<VcardPreview />} />
              <Route path="/images" element={<ImagesPreview />} />
              <Route path="/video" element={<VideoPreview />} />
              <Route path="/pdf" element={<PDFPreview />} />
              <Route path="/app" element={<AppsPreview />} />
              <Route path="/wifi" element={<WIFIPreview />} />
              <Route path="/url" element={<WebsitePreview />} />
              <Route path="/coupon" element={<CouponPreview />} />
              <Route path="/mp3" element={<Mp3Preview />} />
              <Route path="/links" element={<ListOfLinksPreview />} />
              <Route path="/menu" element={<MenuPreview />} />
              <Route path="/business" element={<BusinessPreview />} />
              <Route path="/event" element={<EventPreview />} />
              <Route path="/instagram" element={<InstagramPreview />} />
              <Route path="/instagram-2" element={<InstagramPreviewV2 />} />
              <Route path="/instagram-3" element={<InstagramPreviewV3 />} />
              <Route path="/instagram-4" element={<InstagramPreviewV4 />} />
              <Route path="/facebook" element={<FacebookPreview />} />
              <Route path="/social" element={<SocialMediaPreview />} />
              <Route path="/review" element={<ReviewPreview />} />
              <Route path="/whatsapp" element={<WhatsappPreview />} />
              <Route path="/sms" element={<SMSPreview />} />
              <Route path="/email" element={<EmailPreview />} />
              <Route path="/sms-2" element={<SMS2Preview />} />
              <Route path="/sms-3" element={<SMS3Preview />} />
              <Route path="/sms-4" element={<SMS4Preview />} />
              <Route path="/whatsapp-2" element={<Whatsapp2Preview />} />
              <Route path="/whatsapp-4" element={<Whatsapp4Preview />} />

              <Route path=":domain/preview" element={<StepController />} />
              <Route path="/preview" element={<StepController />} />

              <Route path="*" element={getDefaultPage()} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
        <SpeedInsights />
        <Analytics />
      </div>
    </ThemeProvider>
  );
}

export default App;
