export const getTemplateData = (t)=>{

    /*
        q = oqg
        i = qci
        b = qrb
        a = qai
    */

    return {
        q:{
            pdf:{
                company: 'Enterprise Inc.',
                pdftitle: t('pdf.title'),
                directly_show_pdf: "",
                primaryColor: "#57C4D9",
                SecondaryColor: "#FF9865",
                description: t('pdf.description'),
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                screen: false,
                button: t('pdf.button'),
                pdf: "",
                pdfThumbnail:'',
                website: "#",
                defImg: "/pdf.jpg"
            },
            links:{
                list_title: 'Jenny Leslie',
                list_description: t('links.about'),
                font_text: "GT Walsheim Pro",
                font_title: "GT Walsheim Pro",
                linkTextColor: "#FFF",
                linkColor: "#37C1B4",
                primaryColor: "#37C1B4",
                companyLogo: "/images/images/new/avatar2.webp",
                screen: false,
                linkList: [
                    {
                        name: t('links.link.About1'),
                        image: "/images/ListOfLinks/makeup.png",
                        link: ""
                    },
                    {
                        name: 'Instagram ' +  t('links.link.About2'),
                        image: "/images/ListOfLinks/instagram.png",
                        link: ""
                    },
                    {
                        name: 'TikTok ' +  t('links.link.About3'),
                        image: "/images/ListOfLinks/tiktok.png",
                        link: ""
                    },
                    {
                        name: t('links.link.About4'),
                        image: "/images/ListOfLinks/youtube.png",
                        link: ""
                    },
                    {
                        name: t('links.link.About5'),
                        image: "/images/ListOfLinks/demo.png",
                        link: ""
                    }
                ],
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'X',
                        url: "/images/social/twitter.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('links.social.youtube'),
                        url: "/images/social/youtube.svg"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'TikTok',
                        url: "/images/ListOfLinks/tiktok.png"
                    }
                ]
            },
            vcard:{
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                vcard_profession: t('vcard.profession'),
                primaryColor: "#093A32",
                SecondaryColor: "#093A32",
                vcard_first_name: 'John Carlson',
                vcard_last_name: "",
                socialLinks: [
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: 'LinkedIn',
                        url: "/images/social/linkedin.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: t('vcard.facebook'),
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: t('vcard.google'),
                        url: "/images/social/google.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: t('vcard.instagram'),
                        url: "/images/social/instagram.webp"
                    }
                ],
                vcard_website: [
                    {
                        title: t('vcard.website'),
                        website: "john-carlson.com"
                    }
                ],
                vcard_phone: [
                    {
                        label: "",
                        type: "other",
                        number: "555-100-1000"
                    }
                ],
                vcard_email: [
                    {
                        title: "",
                        email: "John.Carlson@gmail.com"
                    }
                ],
                vcard_note: t('vcard.summary'),
                vcard_company: t('vcard.company_name'),
                vcard_social_label: [],
                vcard_social_value: [],
                images: "/images/images/new/avatar.webp",
                offer_street: t('vcard.location_text'),
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                vcard_add_contact_at_top: 'falseprev',
                street_number: true
            },
            business:{
                contactMobiles: [
                    {
                        title: "",
                        number: "555-100-1000"
                    }
                ],
                contactEmails: [
                    {
                        title: "",
                        email: "Boulangerie@gmail.com"
                    }
                ],
                contactWebsite:[
                    {title:'',website:'www.boulangerie.com'}
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.facebook'),
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.instagram'),
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.google'),
                        url: "/images/social/google.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'LinkedIn',
                        url: "/images/social/linkedin.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'TikTok',
                        url: "/images/ListOfLinks/tiktok.png"
                    }
                ],
                aboutCompany: t('bushiness.about_company_text'),
                weekDays: {
                    Monday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Tuesday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Wednesday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Thursday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Friday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Saturday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Sunday: [
                        {
                            "from": "10:00",
                            "to": "05:00"
                        }
                    ]
                },
                primaryColor: "#1A97F0",
                SecondaryColor: "#0079FF",
                companyLogo: "/images/images/new/business.webp",
                company: t('bushiness.company'),
                companyTitle: t('bushiness.title'),
                companySubtitle: t('bushiness.subtitle'),
                screen: false,
                contactName: 'Boulangerie Pâtissier',
                businessButtons: t('bushiness.button'),
                businessButtonUrls: "#",
                businessButtonsCreated: "0",
                cardContainer: false,
                ship_address: "",
                offer_street: t('bushiness.location_1'),
                offer_number: "",
                offer_postalcode: "",
                offer_city: t('bushiness.location_2'),
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                ficons: [
                    "ficon",
                    "ficon1",
                    "ficon2",
                    "ficon3",
                    "ficon4",
                    "ficon5",
                    "ficon6",
                    "ficon7",
                    "ficon8",
                    // "ficon9",
                    "ficon10",
                    "ficon11",
                    "ficon12"
                ],
                street_number: true
            },
            images:{
                primaryColor: "#28C254",
                image_title: t('images.headerTitle'),
                image_description: t('images.headerDescription'),
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                screen: false,
                website: "www.mypictures.com",
                images: [
                    "/images/images/new/01.webp",
                    "/images/images/new/02.webp"
                ],
                buttons: [
                    {
                        text: t('images.viewMoreButton'),
                        url: "#"
                    }
                ],
                horizontal: true
            },
            video:{
                videos: [],
                direct_video: false,
                Highlight: false,
                Autoplay: false,
                primaryColor: "#AEE301",
                companyName: "Emily’s Kitchen",
                videoTitle: t('video.title'),
                videoDescription: t('video.description'),
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                button_text: t('video.view_more'),
                button_url: "",
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.facebook'),
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.instagram'),
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('links.social.youtube'),
                        url: "/images/social/youtube.svg"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'TikTok',
                        url: "/images/ListOfLinks/tiktok.png"
                    }
                ],
            },
            app:{
                screen: false,
                SecondaryColor: "#CA182B",
                primaryColor: "#CA182B",
                app_title: 'Nourish',
                app_description: t('app.description'),
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                app_website: "www.nourish.com",
                app_company: t('app.base'),
                google: "#",
                apple: "#",
                amazon: "#",
                images: [],
                companyLogo: "/images/images/new/nourish.webp",
            },
            coupon:{
                screen: false,
                ship_address: "",
                company: t('coupon.company'),
                title: t('coupon.salesBadge'),
                description: t('coupon.offerDescription'),
                salesBadge: t('coupon.salesBadge'),
                buttonToSeeCode: t('coupon.offerButton'),
                couponCode: "25%%20OFF",
                validTillDate: "",
                terms: [t('coupon.termsParagraph')],
                buttonText: t('coupon.webButton'),
                buttonUrl: "#",
                primaryColor: "#C12EFE",
                SecondaryColor: "#EDED4F",
                companyLogo: "/images/images/new/sale.webp",
                offerImage: "https://t3.ftcdn.net/jpg/02/55/97/94/360_F_255979498_vewTRAL5en9T0VBNQlaDBoXHlCvJzpDl.jpg",
                offer_street: "",
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                street_number: false,
                flip: false,
                barCodeTgl: true,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro"
            },
            mp3:{
                screen: false,
                addDownloadOption: "",
                button_url: "",
                button_text: t('mp3.viewMoreButton'),
                primaryColor: "#7572FF",
                SecondaryColor: "#7572FF",
                mp3_title: 'Night Drive',
                mp3_description: t('mp3.songDescription'),
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                mp3_website: "#",
                mmp3: [
                    "/empty.mp3"
                ],
                images: [],
                image: "/images/images/new/mp3.webp",
                button_created: "0",
                socialLinks: [ 
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.facebook'),
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.instagram'),
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('links.social.youtube'),
                        url: "/images/social/youtube.svg"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('mp3.social.spotify'),
                        url: "/images/social/spotify.svg"
                    }
                ],
            },
            menu:{
                sections: [
                    {
                        id: "1",
                        name: t('menu.section_1'),
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: t('menu.section_1_product_name'),
                                image: "https://online-qr-generator.com/uploads/menu/645d593b275ff_1_productImages_vegetable-salad.jpeg",
                                translated: "Salat",
                                description: t('menu.section_1_product_desc'),
                                price: "12",
                                allergens: [
                                    "cereals",
                                    "crustaceans"
                                ]
                            }
                        ]
                    },
                    {
                        id: "8",
                        name: t('menu.section_2'),
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: t('menu.section_2_product_name'),
                                image: "https://online-qr-generator.com/uploads/menu/645d593b275ff_78_productImages_Orange.jpg",
                                translated: "Orangensaft",
                                description: t('menu.section_2_product_desc'),
                                price: "8",
                                allergens: [
                                    "peanuts",
                                    "soy"
                                ]
                            }
                        ]
                    },
                    {
                        id: "78",
                        name: t('menu.section_3'),
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: t('menu.section_3_product_name'),
                                image: "https://online-qr-generator.com/uploads/menu/645d593b275ff_8_productImages_donuts.png",
                                translated: "Glasierter Hefe-Donut",
                                description: t('menu.section_3_product_desc'),
                                price: "6",
                                allergens: [
                                    "milk",
                                    "fruits"
                                ]
                            }
                        ]
                    },
                    {
                        id: "139",
                        name: t('menu.section_4'),
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: t('menu.section_4_product_name'),
                                image: "https://online-qr-generator.com/uploads/menu/645d593b275ff_139_productImages_fish.jpg",
                                translated: "Gebratener Lachs mit Walnuss",
                                description: t('menu.section_4_product_desc'),
                                price: "45",
                                allergens: [
                                    "eggs",
                                    "fish"
                                ]
                            }
                        ]
                    }
                ],
                socialLinks: [
                    {
                        name: "",
                        text: "",
                        icon: "Facebook",
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: "",
                        icon: "Instagram",
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: "",
                        icon: "Google",
                        url: "/images/social/google.png"
                    },
                    {
                        name: "",
                        text: "",
                        icon: "X",
                        url: "/images/social/twitter.png"
                    },
                    {
                        name: "",
                        text: "",
                        icon: "TikTok",
                        url: "/images/ListOfLinks/tiktok.png"
                    }
                ],
                contactMobiles: [
                    {
                        title: "",
                        number: "555-100-1000"
                    }
                ],
                contactEmails: [
                    {
                        title: "",
                        email: "TastyFood@gmail.com"
                    }
                ],
                activeId: "",
                // contactName: t('menu.restaurant_name'),
                contactWebsite:[
                    {title:'',website:'www.tastyfood.com'}
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                primaryColor: "#FF6228",
                companyLogo: "/images/images/new/tastyfood.webp",
                companyTitle: t('menu.restaurant_name'),
                companyDescription: t('menu.restaurant_desc'),
                screen: false,
                weekDays: {
                    Saturday: [
                        {
                            from: "10:00",
                            to: "14:00"
                        },
                        {
                            from: "17:00",
                            to: "23:00"
                        }
                    ],
                    Sunday: [
                        {
                            from: "10:00",
                            to: "17:00"
                        }
                    ],
                    Monday: [
                        {
                            from: "10:00",
                            to: "17:00"
                        }
                    ],
                    Tuesday: [
                        {
                            from: "10:00",
                            to: "17:00"
                        }
                    ],
                    Wednesday: [
                        {
                            from: "10:00",
                            to: "17:00"
                        }
                    ],
                    Thursday: [
                        {
                            from: "10:00",
                            to: "17:00"
                        }
                    ]
                },
                areAllDaysNull: true
            },
            event:{
                screen: false,
                primaryColor: "#00296B",
                title: "Quantum Tech Conference",
                description: "Join us for an innovative conference combining insightful learning and entertainment.",
                image: "/images/images/new/event.webp",
                buttonText: "View More",
                buttonUrl: "#",
                dates: {
                    startDateText: "Thursday, February 8, 2024",
                    startDate: "2024-04-21",
                    startTime: "09:00",
                    endDateText: "Friday, February 9, 2024",
                    endDate: "2024-05-14",
                    endTime: "16:00",
                    timeZone: "Africa/Cairo",
                    preview: true
                },
                aboutEvent: "Dive into the future of tech at our exciting conference! Enjoy insightful sessions and entertaining showcases for valuable learning and unforgettable experiences.",
                location: {
                    street: "Gower Street",
                    st_number: "49",
                    postalcode: "",
                    city: "London",
                    state: "WC1F 2FA",
                    country: "United Kingdom",
                    url: "#",
                    latitude: "",
                    longitude: "",
                    street_number: true,
                },
                ficons: [
                    "ficon",
                    "ficon1",
                    "ficon2",
                    "ficon3",
                    "ficon4",
                    "ficon5",
                    "ficon6",
                    "ficon7",
                    "ficon8",
                    "ficon9",
                    "ficon10",
                    "ficon11",
                    // "ficon12",
                    // "ficon13",
                    // "ficon14",
                ],
                contactName: "George Birmingham",
                websites: [
                    {
                        title: "Website",
                        website: "www.quantech.com"
                    },
                ],
                phones: [
                    {
                        label: "Phone",
                        type: "other",
                        number: "2435243523"
                    },
                ],
                emails: [
                    {
                        title: "Email",
                        email: "george.bir@quantech.com"
                    }
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
            },
            facebook:{
                screen: false,
                cover: "/images/images/new/fb_cover.png",
                profileImg: "/images/images/new/fb_pic.webp",
                title: t('facebook.title'),
                description: t('facebook.description'),
                pageUrl: "#",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                hide_like:false,
                page_likes:'5.8k'
            },
            social:{
                screen: false,
                primaryColor: "#A8BDE2",
                images: [
                    "/images/images/new/social media/1.webp",
                    "/images/images/new/social media/2.webp",
                    "/images/images/new/social media/3.webp",
                ],
                title: "Ariel Campbell",
                description: t('social.description'),
                socialLinks: [
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Facebook",
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Instagram",
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "TikTok",
                        url: "/images/social/tiktok.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "YouTube",
                        url: "/images/social/youtube.png"
                    },
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                horizontal: false
            },
            review:{
                screen: false,
                primaryColor: "#97000F",
                type: "both", //public, private or both
                publicReviewType: {
                    image: "/images/images/new/google.svg",
                    title: "Google Rating",
                },
                emailInput: true,
                phoneInput: true,
                title: "Los Angeles Bus Tour",
                image: "/images/images/new/rate.webp",
                website: "www.bustour.com",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
            },
            instagram:{
                username: t('instagram.username'),
                step: 1,
                live: true
            },
            email:{
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                primaryColor: "#8966A6",
                title: "Green Life Architect",
                first_name: "Patrick",
                last_name: "Sandres",
                description: "Reach out to us! \n Contact our sales team for a quote or ask a question to our support team.",
                image: "/images/images/new/email-bg.png",
                websites: [
                    {
                        title: "Website",
                        website: "www.glarchitect.com"
                    },
                ],
                phones: [
                    {
                        label: "Phone",
                        type: "other",
                        number: "(001) 555-1004"
                    },
                ],
                emails: [
                    {
                        title: "Email",
                        email: "info@greenlife.com"
                    }
                ],
                buttons : [
                    {
                        title: "Contact sales",
                        email: "#",
                        subject: "",
                        body: "",
                        icon: "email",
                        // color: "pink"
                    },
                    {
                        title: "Ask us any questions",
                        email: "#",
                        subject: "",
                        body: "",
                        icon: "headest",
                        // color: "purple"
                    },
                ],
            },
            whatsapp:{
                number: "+123 456 789",
                message: "",
                step: 1,
                live: true
            },
            sms:{
                number: "+123 456 7890",
                message: "",
                live: true
            },

        },
        i:{
            pdf: {
                company: 'North American Accountants, Inc.',
                pdftitle: t('qrc_io.pdf.title'),
                directly_show_pdf: "",
                primaryColor: "#F37736",
                description:  t('qrc_io.pdf.description'),
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                screen: false,
                button: t('pdf.button'),
                pdf: "",
                pdfThumbnail:'',
                website: "www.naai.com",
                defImg: "/pdf.jpg"
            },
            links:{
                list_title: 'Julia Anderson',
                list_description:  t('qrc_io.links.about'),
                font_text: "GT Walsheim Pro",
                font_title: "GT Walsheim Pro",
                primaryColor: "#104E6D",
                companyLogo: "/images/images/new/v2/links/julia.png",
                screen: false,
                linkList: [
                    {
                        name: t('qrc_io.links.link.About1'),
                        image: "/images/images/new/v2/links/julia.png",
                        link: ""
                    },
                    {
                        name: t('qrc_io.links.link.About2'),
                        image: "/images/images/new/v2/links/youtube.png",
                        link: ""
                    },
                    {
                        name: t('qrc_io.links.link.About3'),
                        image: "/images/images/new/v2/links/tiktok.png",
                        link: ""
                    },
                    {
                        name: t('qrc_io.links.link.About4'),
                        image: "/images/images/new/v2/links/fitness.png",
                        link: ""
                    },
                    {
                        name: t('qrc_io.links.link.About5'),
                        image: "/images/images/new/v2/links/fiton.png",
                        link: ""
                    }
                ],
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'WhatsApp',
                        url: "/images/social/whatsapp.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'Instagram',
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'Facebook',
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'x',
                        url: "/images/social/x.png"
                    },
                ]
            },
            vcard:{
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                vcard_profession: t('qrc_io.vcard.company_name'),
                primaryColor: "#467EF9",
                vcard_first_name: 'David Elson',
                vcard_last_name: "",
                socialLinks: [
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: 'LinkedIn',
                        url: "/images/social/linkedin-full.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: t('vcard.facebook'),
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: t('vcard.google'),
                        url: "/images/social/google-transparent.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: t('vcard.instagram'),
                        url: "/images/social/instagram.webp"
                    }
                ],
                vcard_website: [
                    {
                        title: t('vcard.website'),
                        website: "www.creativedesign.com"
                    }
                ],
                vcard_phone: [
                    {
                        label: "",
                        type: "other",
                        number: "(123) 555-2000"
                    }
                ],
                vcard_email: [
                    {
                        title: "",
                        email: "david.e@creativedesign.com"
                    }
                ],
                vcard_note: t('qrc_io.vcard.summary'),
                vcard_company: t('qrc_io.vcard.company'),
                vcard_social_label: [],
                vcard_social_value: [],
                images: "/images/images/new/v2/vcard_avatar.jpg",
                offer_street: t('qrc_io.vcard.location_text'),
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                vcard_add_contact_at_top: 'falseprev',
                street_number: true
            },
            business:{
                contactMobiles: [
                    {
                        title: "",
                        number: "(123) 555-1000"
                    }
                ],
                contactEmails: [
                    {
                        title: "",
                        email: "lori@holidaytoyshop.com"
                    }
                ],
                contactWebsite:[
                    {
                        title:'',
                        website:'www.holidaytoyshop.com'
                    }
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.facebook'),
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.instagram'),
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.google'),
                        url: "/images/social/google-transparent.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'LinkedIn',
                        url: "/images/social/linkedin-full.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'TikTok',
                        url: "/images/ListOfLinks/tiktok.png"
                    }
                ],
                aboutCompany: t('qrc_io.business.about_company_text'),
                weekDays: {
                    Monday: [
                        {
                            "from": "10:00",
                            "to": "16:00"
                        },
                    ],
                    Tuesday: [
                        {
                            "from": "09:00",
                            "to": "17:00"
                        }
                    ],
                    Wednesday: [
                        {
                            "from": "09:00",
                            "to": "17:00"
                        }
                    ],
                    Thursday: [
                        {
                            "from": "09:00",
                            "to": "17:00"
                        }
                    ],
                    Friday: [
                        {
                            "from": "10:00",
                            "to": "21:00"
                        }
                    ],
                    Saturday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                },
                primaryColor: "#8E413B",
                SecondaryColor: "#D98902",
                companyLogo: "/images/images/new/v2/business.webp",
                company: t('qrc_io.business.company'),
                companyTitle: 'Holiday Toy Shop',
                companySubtitle: t('qrc_io.business.description'),
                screen: false,
                contactName: t('qrc_io.business.name_text'),
                businessButtons: t('qrc_io.business.button'),
                businessButtonUrls: "#",
                businessButtonsCreated: "0",
                cardContainer: false,
                ship_address: "",
                offer_street: '3274 Doe Meadow Drive Annapolis Junction, MD 20701',
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                ficons: [
                    "ficon",
                    "ficon1",
                    "ficon2",
                    "ficon3",
                    "ficon4",
                    "ficon5",
                    "ficon6",
                    "ficon7",
                    "ficon8",
                    "ficon9",
                    "ficon10",
                    "ficon11",
                    // "ficon12",
                    // "ficon13",
                    // "ficon14",
                ],
                street_number: true
            },
            images:{
                primaryColor: "#007964",
                image_title: t('qrc_io.images.headerTitle'),
                image_description: t('qrc_io.images.headerDescription'),
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                screen: false,
                website: "www.besttravelphotos.net",
                images: [
                    "/images/images/new/v2/image1.webp",
                    "/images/images/new/v2/image2.webp",
                ],
                buttons: [
                    {
                        text: t('images.viewMoreButton'),
                        url: "#"
                    }
                ],
                horizontal: true
            },
            video:{
                videos: [],
                direct_video: false,
                Highlight: false,
                Autoplay: false,
                primaryColor: "#C70225",
                companyName:  "Sandra’s Health and Wellness",
                videoTitle: t('qrc_io.video.title'),
                videoDescription: t('qrc_io.video.description'),
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                button_text: t('video.view_more'),
                button_url: "",
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.instagram'),
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('links.social.youtube'),
                        url: "/images/social/youtube.svg"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'TikTok',
                        url: "/images/ListOfLinks/tiktok.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.facebook'),
                        url: "/images/social/facebook.png"
                    },
                ],
            },
            app:{
                screen: false,
                SecondaryColor: "#6D40CE",
                primaryColor: "#6D40CE",
                app_title: 'Mindful Comfort',
                app_description: t('qrc_io.app.description'),
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                app_website: "www.mindfulcomfort.com",
                app_company: t('qrc_io.app.base'),
                google: "#",
                apple: "#",
                amazon: "#",
                images: [],
                companyLogo: "/images/images/new/v2/app.png",
            },
            coupon:{
                screen: false,
                newShareIcon: true,
                ship_address: "",
                company:t('qrc_io.coupon.company'),
                title: t('qrc_io.coupon.salesBadge'),
                description: t('qrc_io.coupon.offerDescription'),
                salesBadge: t('coupon.salesBadge'),
                buttonToSeeCode: t('coupon.offerButton'),
                couponCode: "25%%20OFF",
                validTillDate: "",
                terms: [t('coupon.termsParagraph')],
                buttonText: t('coupon.webButton'),
                buttonUrl: "#",
                primaryColor: "#E17D3F",
                SecondaryColor: "#EDED4F",
                companyLogo: "/images/images/new/v2/coupon.webp",
                offerImage: "https://t3.ftcdn.net/jpg/02/55/97/94/360_F_255979498_vewTRAL5en9T0VBNQlaDBoXHlCvJzpDl.jpg",
                offer_street: "",
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                street_number: false,
                flip: false,
                barCodeTgl: true,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro"
            },
            mp3:{
                screen: false,
                addDownloadOption: "",
                button_url: "",
                button_text: t('qrc_io.mp3.viewMoreButton'),
                primaryColor: "#0F5B72",
                SecondaryColor: "#0F5B72",
                mp3_title: 'Cruising Beats',
                mp3_description: t('qrc_io.mp3.songDescription'),
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                mp3_website: "www.thestorms.com",
                mmp3: [
                    "/empty.mp3"
                ],
                images: [],
                image: "/images/images/new/v2/mp3.webp",
                button_created: "0",
                socialLinks: [ 
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "Spotify",
                        url: "/images/social/spotify.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('links.social.youtube'),
                        url: "/images/social/youtube.svg"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "TikTok",
                        url: "/images/social/tiktok.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "Soundcloud",
                        url: "/images/social/soundcloud.png"
                    }
                ],
                socialLinksTitle: "Find me on"
            },
            event:{
                screen: false,
                primaryColor: "#07B8B8",
                title: "New York HR Roundtable",
                description: "An open forum for HR professionals from different departments to share challenges and brainstorm solutions.",
                image: "/images/images/new/v2/event.webp",
                buttonText: "View More",
                buttonUrl: "#",
                dates: {
                    startDateText: "Monday, March 11, 2024",
                    startDate: "2024-04-21",
                    startTime: "08:00",
                    endDateText: "Wednesday, March 13, 2024",
                    endDate: "2024-05-14",
                    endTime: "16:00",
                    timeZone: "Africa/Cairo",
                    preview: true
                },
                aboutEvent: "Join the region’s leading human resources conference! We’ll discuss everything from recruiting and onboarding to severance packages and more.",
                location: {
                    street: "Main Street",
                    st_number: "2323",
                    postalcode: "14850",
                    city: "Ithaca",
                    state: "NY",
                    country: "",
                    url: "#",
                    latitude: "",
                    longitude: "",
                    street_number: true,
                },
                ficons: [
                    "ficon",
                    "ficon1",
                    "ficon2",
                    "ficon3",
                    "ficon4",
                    "ficon5",
                    "ficon6",
                    "ficon7",
                    "ficon8",
                    "ficon9",
                    "ficon10",
                    "ficon11",
                    // "ficon12",
                    // "ficon13",
                    // "ficon14",
                ],
                contactName: "Corina McCoy",
                websites: [
                    {
                        title: "Website",
                        website: "www.nyhrroundtable.com"
                    },
                ],
                phones: [
                    {
                        label: "Phone",
                        type: "other",
                        number: "(123) 555-4000"
                    },
                ],
                emails: [
                    {
                        title: "Email",
                        email: "corina@nyhrroundtable.com"
                    }
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
            },
            facebook:{
                screen: false,
                cover: "/images/images/new/v2/fb_cover.webp",
                profileImg: "/images/images/new/fb_pic.webp",
                title: t('qrc_io.facebook.title'),
                description: t('qrc_io.facebook.description'),
                pageUrl: "#",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                hide_like:false,
                page_likes:'665k'
            },
            social:{
                screen: false,
                primaryColor: "#DE6C7B",
                images: [
                    "/images/images/new/v2/socialMedia/1.webp",
                    "/images/images/new/v2/socialMedia/2.webp",
                    "/images/images/new/v2/socialMedia/3.webp",
                ],
                title: "Sarah Ann Peters",
                description: t('social.description'),
                socialLinks: [
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Facebook",
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Instagram",
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "TikTok",
                        url: "/images/social/tiktok.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "YouTube",
                        url: "/images/social/youtube.png"
                    },
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                horizontal: false
            },
            email:{
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                primaryColor: "#B5D8EC",
                title: "Premium Law Firm",
                first_name: "Premium Law",
                last_name: "Firm",
                description: "Contact us for legal inquiries or support. \n We're here to assist you!",
                image: "/images/images/new/v2/email-bg.jpg",
                websites: [
                    {
                        title: "Website",
                        website: "www.premiumlawfirm.com"
                    },
                ],
                phones: [
                    {
                        label: "Phone",
                        type: "other",
                        number: "(000) 555-1080"
                    },
                ],
                emails: [
                    {
                        title: "Email",
                        email: "info@premiumlaw.com"
                    }
                ],
                buttons : [
                    {
                        title: "Corporate Law Department",
                        email: "#",
                        subject: "",
                        body: "",
                        icon: "email",
                    },
                    {
                        title: "Litigation Department",
                        email: "#",
                        subject: "",
                        body: "",
                        icon: "headest",
                    },
                ],
            },
            menu:{
                roundedLogo: true,
                sections: [
                    {
                        id: "1",
                        name: t('qrc_io.menu.section_1'),
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Avocado and Shrimp Salad",
                                image: "/images/images/new/v2/menu/avocado-and-shrimp-salad.webp",
                                translated: "Salade d'Avocat et Crevettes",
                                description: "Fresh avocado slices and succulent shrimp on a bed of mixed greens, drizzled with a light lemon vinaigrette.",
                                price: "$9",
                                allergens: [
                                    "crustaceans",
                                    "mustard",
                                ]
                            }
                        ]
                    },
                    {
                        id: "8",
                        name: t('qrc_io.menu.section_2'),
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Herb-Crusted Salmon",
                                image: "/images/images/new/v2/menu/herb-crusted-salmon.webp",
                                translated: "Saumon en Croûte d'Herbes",
                                description: "Oven-baked salmon fillet with a crispy herb crust, served with garlic mashed potatoes and steamed asparagus.",
                                price: "$27",
                                allergens: [
                                    "fish",
                                    "fruits"
                                ]
                            }
                        ]
                    },
                    {
                        id: "78",
                        name: t('qrc_io.menu.section_3'),
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Sparkling Citrus Cooler",
                                image: "/images/images/new/v2/menu/sparkling-citrus-coolerwebp.webp",
                                translated: "Rafraîchisseur d'Agrumes Pétillant",
                                description: "A refreshing blend of sparkling water, freshly squeezed orange and lemon juice, with a hint of mint.",
                                price: "$7",
                                allergens: [
                                    "sulfur",
                                ]
                            }
                        ]
                    },
                    {
                        id: "139",
                        name: t('qrc_io.menu.section_4'),
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Chocolate Lava Cake",
                                image: "/images/images/new/v2/menu/chocolate-lava-cake.webp",
                                translated: "Gâteau Fondant au Chocolat",
                                description: "Warm, gooey chocolate cake with a molten center, served with a scoop of vanilla ice cream.",
                                price: "$11",
                                allergens: [
                                    "milk",
                                    "cereals"
                                ]
                            }
                        ]
                    }
                ],
                socialLinks: [
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Uber Eats",
                        url: "/images/social/uber.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Google",
                        url: "/images/social/google-transparent.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Instagram",
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Facebook",
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "TikTok",
                        url: "/images/social/tiktok2.png"
                    }
                ],
                contactMobiles: [
                    {
                        title: "",
                        number: "(123) 555-3000"
                    }
                ],
                contactEmails: [
                    {
                        title: "",
                        email: "alex@alexcuisine.com"
                    }
                ],
                activeId: "",
                contactName: t('qrc_io.menu.contact_name'),
                contactWebsite:[
                    {
                        title:'',
                        website:'www.alexandrecuisine.com'
                    }
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                primaryColor: "#B64836",
                companyLogo: "/images/images/new/v2/menu.webp",
                companyTitle: t('qrc_io.menu.contact_name'),
                companyDescription: t('qrc_io.menu.subtitle'),
                screen: false,
                weekDays: {
                    Monday: [
                        {
                            from: "10:00",
                            to: "15:00"
                        },
                        {
                            from: "17:00",
                            to: "23:00"
                        },
                    ],
                    Tuesday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                    Wednesday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                    Thursday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                    Friday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                    Saturday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                },
                areAllDaysNull: false
            },
            review:{
                screen: false,
                primaryColor: "#D97150",
                type: "both", //public, private or both
                publicReviewType: {
                    image: "/images/images/new/google.svg",
                    title: "Google Rating",
                },
                emailInput: true,
                phoneInput: true,
                title: "North Ferris Wheel",
                image: "/images/images/new/v2/review.webp",
                website: "www.beststockphotos.com",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
            },
            instagram:{
                username: t('instagram.username'),
                step: 1,
                live: true
            },
            whatsapp:{
                number: "+123 456 789",
                step: 1,
                message: "",
                live: true
            },
        },
        a:{
            pdf: {
                company: 'Software Experts, Inc.',
                pdftitle: 'Service Overview',
                directly_show_pdf: "",
                primaryColor: "#5265C9",
                description:  'We produce many popular apps. Learn more about our latest offerings today!',
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                screen: false,
                button: t('pdf.button'),
                pdf: "",
                pdfThumbnail:'',
                website: "www.softwareexpertsinc.com",
                defImg: "/pdf.jpg"
            },
            links:{
                list_title: 'Stephanie Nichols',
                list_description:  'Follow all of my social media channels for travel tips and inspirational stories!',
                font_text: "GT Walsheim Pro",
                font_title: "GT Walsheim Pro",
                primaryColor: "#AED0C9",
                companyLogo: "/images/images/new/v3/links/stephanie.png",
                screen: false,
                linkList: [
                    {
                        name: 'Travel Stories on My Blog',
                        image: "/images/images/new/v3/links/stephanie.png",
                        link: ""
                    },
                    {
                        name: "Travel Tips on My TikTok",
                        image: "/images/images/new/v2/links/tiktok.png",
                        link: ""
                    },
                    {
                        name: "Vacation in the Maldives",
                        image: "/images/images/new/v2/links/youtube.png",
                        link: ""
                    },
                    {
                        name: "Flight Booking App",
                        image: "/images/images/new/v3/links/flight.png",
                        link: ""
                    },
                ],
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'Instagram',
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'YouTube',
                        url: "/images/images/new/v2/links/youtube.png"
                    },
                    
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'TikTok',
                        url: "/images/social/tiktok.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'Facebook',
                        url: "/images/social/facebook.png"
                    },
                ]
            },
            vcard:{
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                vcard_profession: "Senior Web Developer",
                primaryColor: "#CD4F41",
                vcard_first_name: "Kyle Thorsen",
                vcard_last_name: "",
                socialLinks: [
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: 'WhatsApp',
                        url: "/images/social/whatsapp.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: 'LinkedIn',
                        url: "/images/social/linkedin-full.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: t('vcard.facebook'),
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: "X",
                        url: "/images/social/x.png"
                    },
                ],
                vcard_website: [
                    {
                        title: t('vcard.website'),
                        website: "www.webcrafters.com"
                    }
                ],
                vcard_phone: [
                    {
                        label: "",
                        type: "other",
                        number: "(001) 555-1000"
                    }
                ],
                vcard_email: [
                    {
                        title: "",
                        email: "kyle@webcrafters.com"
                    }
                ],
                vcard_note: "As a senior web developer at WebCrafters Studio, I am proud to provide the best website development service in Europe!",
                vcard_company: "WebCrafters Studio",
                vcard_social_label: [],
                vcard_social_value: [],
                images: "/images/images/new/v3/vcard_avatar.webp",
                offer_street: "82 Liberté Street, 75012 Paris, France",
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                vcard_add_contact_at_top: 'falseprev',
                street_number: true
            },
            business:{
                contactMobiles: [
                    {
                        title: "",
                        number: "(001) 555-1001"
                    }
                ],
                contactEmails: [
                    {
                        title: "",
                        email: "frances@nwgemstone.com"
                    }
                ],
                contactWebsite:[
                    {
                        title:'',
                        website:'www.nwgemstone.com'
                    }
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'LinkedIn',
                        url: "/images/social/linkedin-full.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.google'),
                        url: "/images/social/google-transparent.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.facebook'),
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.instagram'),
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'X',
                        url: "/images/social/x.png"
                    }
                ],
                aboutCompany: "Handcrafted jewelry offers a unique and personal way to elevate your everyday style. Explore our incredible selection and find your next piece today!",
                weekDays: {
                    Monday: [
                        {
                            "from": "10:00",
                            "to": "14:00"
                        },
                        {
                            "from": "17:00",
                            "to": "20:00"
                        },
                    ],
                    Tuesday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Wednesday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Thursday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Friday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Saturday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                },
                primaryColor: "#E49663",
                SecondaryColor: "#E49663",
                companyLogo: "/images/images/new/v3/business.webp",
                company: "Explore Our Fine Jewelry!",
                companyTitle: "Northwest Gemstone Gallery",
                companySubtitle: "Shop our unique designs and find your next piece of everyday elegance!",
                screen: false,
                contactName: "Frances Swann",
                businessButtons: t('qrc_io.business.button'),
                businessButtonUrls: "#",
                businessButtonsCreated: "0",
                cardContainer: false,
                ship_address: "",
                offer_street: '123 Main Street Seattle, WA 98107',
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                ficons: [
                    "ficon",
                    "ficon1",
                    "ficon2",
                    "ficon3",
                    "ficon4",
                    "ficon5",
                    "ficon6",
                    "ficon7",
                    "ficon8",
                    "ficon9",
                    "ficon10",
                    "ficon11",
                    // "ficon12",
                    // "ficon13",
                    // "ficon14",
                ],
                street_number: true
            },
            images:{
                primaryColor: "#DD6332",
                image_title: "Nature’s Canvas",
                image_description: "Browse our gallery of nature photos and order prints of any image!",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                screen: false,
                website: "www.naturescanvas.com",
                images: [
                    "/images/images/new/v3/image1.webp",
                    "/images/images/new/v3/image2.webp",
                ],
                buttons: [
                    {
                        text: 'View All',
                        url: "#"
                    }
                ],
                horizontal: true
            },
            video:{
                videos: [],
                direct_video: false,
                Highlight: false,
                Autoplay: false,
                primaryColor: "#672241",
                companyName: "Kyle’s Rock Band Lessons",
                videoTitle: "Open Jam Sessions",
                videoDescription: "Want to be part of a rock band? Join our next jam session and rock out with likeminded musicians!",
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                button_text: t('video.view_more'),
                button_url: "",
                socialLinks: [
                    
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('links.social.youtube'),
                        url: "/images/social/youtube.svg"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'Soundcloud',
                        url: "/images/social/soundcloud.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "TikTok",
                        url: "/images/social/tiktok.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.instagram'),
                        url: "/images/social/instagram.webp"
                    },
                ],
            },
            app:{
                screen: false,
                SecondaryColor: "#EE4035",
                primaryColor: "#EE4035",
                app_title: "Vitality Health & Fitness",
                app_description: "Stay active and achieve your fitness milestones with the Vitality Health & Fitness app. ",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                app_website: "www.vitalityapp.com",
                app_company: "Fitness Tracking App",
                google: "#",
                apple: "#",
                amazon: "#",
                images: [],
                companyLogo: "/images/images/new/v3/app.png",
            },
            coupon:{
                screen: false,
                newShareIcon: true,
                ship_address: "",
                company: "The Four Seasons",
                title: "20% OFF",
                description: "This discount applies to all products in our online store — act now before the sale ends!",
                salesBadge: "20% OFF",
                buttonToSeeCode: "CLAIM COUPON",
                couponCode: "20%%20OFF",
                validTillDate: "",
                terms: [t('coupon.termsParagraph')],
                buttonText: t('coupon.webButton'),
                buttonUrl: "#",
                primaryColor: "#3D6732",
                SecondaryColor: "#ECEC4F",
                ThirdColor: "#000000",
                companyLogo: "/images/images/new/v3/coupon.webp",
                offerImage: "https://t3.ftcdn.net/jpg/02/55/97/94/360_F_255979498_vewTRAL5en9T0VBNQlaDBoXHlCvJzpDl.jpg",
                offer_street: "",
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                street_number: false,
                flip: false,
                barCodeTgl: true,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro"
            },
            mp3:{
                screen: false,
                addDownloadOption: "",
                button_url: "",
                button_text: "Access Playlist",
                primaryColor: "#EACD94",
                SecondaryColor: "#D7B166",
                mp3_title: "Bright Lights and Lullabies",
                mp3_description: "Jamie Abernathy",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                mp3_website: "www.jamieabernathy.com",
                mmp3: [
                    "/empty.mp3"
                ],
                images: [],
                image: "/images/images/new/v3/mp3.webp",
                button_created: "0",
                socialLinks: [ 
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "YouTube",
                        url: "/images/images/new/v2/links/youtube.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "Apple Music",
                        url: "/images/social/apple-music.png"
                    },
                    
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "Spotify",
                        url: "/images/social/spotify.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "TikTok",
                        url: "/images/social/tiktok.png"
                    },
                ],
                socialLinksTitle: "Find me on"
            },
            event:{
                screen: false,
                primaryColor: "#C178C8",
                title: "Bells and Brides",
                description: "A bohemian wedding expo with a focus on natural beauty, wildflowers, and outdoor festivities.",
                image: "/images/images/new/v3/event.webp",
                buttonText: "View More",
                buttonUrl: "#",
                dates: {
                    startDateText: "Saturday, July 6",
                    startDate: "2024-07-06",
                    startTime: "14:00",
                    endDateText: "Sunday, July 7",
                    endDate: "2024-07-07",
                    endTime: "03:00",
                    timeZone: "Africa/Cairo",
                    preview: true
                },
                aboutEvent: "Join us for a two-day wedding industry expo! We’ll discuss decor, venues, clothing, and so much more.",
                location: {
                    street: "3274 1st Ave N Annapolis Junction",
                    st_number: "",
                    postalcode: "20701",
                    city: "",
                    state: "MD",
                    country: "",
                    url: "#",
                    latitude: "",
                    longitude: "",
                    street_number: true,
                },
                ficons: [
                    "ficon",
                    "ficon1",
                    "ficon2",
                    "ficon3",
                    "ficon4",
                    "ficon5",
                    "ficon6",
                    "ficon7",
                    "ficon8",
                    "ficon9",
                    "ficon10",
                    "ficon11",
                    // "ficon12",
                    // "ficon13",
                    // "ficon14",
                ],
                contactName: "Kathy Johnson",
                websites: [
                    {
                        title: "Website",
                        website: "www.bellsandbrides.com"
                    },
                ],
                phones: [
                    {
                        label: "Phone",
                        type: "other",
                        number: "(001) 555-1003"
                    },
                ],
                emails: [
                    {
                        title: "Email",
                        email: "kathy@bellsandbrides.com"
                    }
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
            },
            facebook:{
                screen: false,
                cover: "/images/images/new/v3/fb_cover.webp",
                profileImg: "/images/images/new/fb_pic.webp",
                title: 'Fashion Inspiration',
                description: 'Want to get great ideas for your next outfit? The Fashion Inspiration Facebook Group has plenty of tips and tricks to help you find hot looks for every occasion!',
                pageUrl: "#",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                hide_like:false,
                page_likes:'665k'
            },
            social:{
                screen: false,
                primaryColor: "#01817D",
                images: [
                    "/images/images/new/v3/socialMedia/1.webp",
                    "/images/images/new/v3/socialMedia/2.webp",
                    "/images/images/new/v3/socialMedia/3.webp",
                ],
                title: "Rachel Louise Mendes",
                description: "Hello! I’m Rachel Louise Mendes. Let’s connect on social media using the links below! I can’t wait to share my latest content with you.",
                socialLinks: [
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Instagram",
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "TikTok",
                        url: "/images/social/tiktok.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Facebook",
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "X",
                        url: "/images/social/x.png"
                    },
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                horizontal: false
            },
            email:{
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                primaryColor: "#FF7B01",
                title: "Fox Coder",
                first_name: "Fox Coder Inc.",
                last_name: "",
                description: "Contact us for business inquiries or get in touch with our support team. We're here to help!",
                image: "/images/images/new/v3/email-bg.webp",
                websites: [
                    {
                        title: "Website",
                        website: "www.foxcoder.com"
                    },
                ],
                phones: [
                    {
                        label: "Phone",
                        type: "other",
                        number: "(448) 555-5000"
                    },
                ],
                emails: [
                    {
                        title: "Email",
                        email: "support@foxcoder.com"
                    }
                ],
                buttons : [
                    {
                        title: "Contact sales",
                        email: "#",
                        subject: "",
                        body: "",
                        icon: "email",
                    },
                    {
                        title: "Ask us any questions",
                        email: "#",
                        subject: "",
                        body: "",
                        icon: "headest",
                    },
                ],
            },
            menu:{
                roundedLogo: true,
                sections: [
                    {
                        id: "1",
                        name: "Appetizers",
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Grilled Eggplant Bruschetta",
                                image: "/images/images/new/v3/menu/Grilled-Eggplant-Bruschetta.webp",
                                translated: "Bruschetta de Berenjena a la Parrilla",
                                description: "Slices of grilled eggplant topped with a fresh tomato and basil mixture, drizzled with balsamic glaze.",
                                price: "$10",
                                allergens: [
                                    "milk",
                                ]
                            }
                        ]
                    },
                    {
                        id: "8",
                        name: "Beverages",
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Tropical Mango Smoothie",
                                image: "/images/images/new/v3/menu/Tropical-Mango-Smoothie.webp",
                                translated: "Batido Tropical de Mango",
                                description: "A creamy blend of ripe mango, pineapple, and coconut milk, served chilled.",
                                price: "$6",
                                allergens: [
                                    "sulfur",
                                    "milk",
                                    "soy"
                                ]
                            }
                        ]
                    },
                    {
                        id: "78",
                        name: "Main Dishes",
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Spicy Chicken Enchiladas",
                                image: "/images/images/new/v3/menu/Spicy-Chicken-Enchiladas.webp",
                                translated: "Enchiladas de Pollo Picante",
                                description: "Corn tortillas filled with shredded chicken and cheese, baked in a spicy red sauce, and topped with sour cream and cilantro.",
                                price: "$18",
                                allergens: [
                                    "peanuts",
                                    "lupins",
                                    "eggs",
                                ]
                            }
                        ]
                    },
                    {
                        id: "139",
                        name: t('qrc_io.menu.section_4'),
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Caramel Flan",
                                image: "/images/images/new/v3/menu/Caramel-Flan.webp",
                                translated: "Flan de Caramelo",
                                description: "A classic creamy caramel flan with a rich, smooth texture and a caramel sauce.",
                                price: "$11",
                                allergens: [
                                    "fruits",
                                    "milk",
                                ]
                            }
                        ]
                    }
                ],
                socialLinks: [
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Uber Eats",
                        url: "/images/social/uber.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "DoorDash",
                        url: "/images/social/doordash.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Google",
                        url: "/images/social/google-transparent.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Instagram",
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Facebook",
                        url: "/images/social/facebook.png"
                    },
                    
                ],
                contactMobiles: [
                    {
                        title: "",
                        number: "(001) 555-1002"
                    }
                ],
                contactEmails: [
                    {
                        title: "",
                        email: "stephanie@thecuisine.com"
                    }
                ],
                activeId: "",
                contactName: "Stephanie Smith",
                contactWebsite:[
                    {
                        title:'',
                        website:'www.thecuisine.com'
                    }
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                primaryColor: "#00A2D5",
                companyLogo: "/images/images/new/v3/menu.webp",
                companyTitle: "The Cuisine",
                companyDescription: "New American Food and Beverage",
                screen: false,
                weekDays: {
                    Monday: [
                        {
                            from: "10:00",
                            to: "15:00"
                        },
                        {
                            from: "17:00",
                            to: "23:00"
                        },
                    ],
                    Tuesday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                    Wednesday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                    Thursday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                    Friday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                    Saturday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                },
                areAllDaysNull: false
            },
            review:{
                screen: false,
                primaryColor: "#BCA67C",
                type: "both", //public, private or both
                publicReviewType: {
                    image: "/images/images/new/google.svg",
                    title: "Google Rating",
                },
                emailInput: true,
                phoneInput: true,
                title: "Downtown Trolley Tours",
                image: "/images/images/new/v3/review.webp",
                website: "www.downtowntrolley.com",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
            },
            instagram:{
                username: t('instagram.username'),
                live: true
            },
            whatsapp:{
                number: "+123 456 789",
                message: "",
                live: true
            },
        },
        b:{
            pdf: {
                company: 'Best Business Bank, Inc.',
                pdftitle: 'Corporate Checking & Savings',
                directly_show_pdf: "",
                primaryColor: "#EE4035",
                description:  'Download your latest statement and take control of your company’s financial future!',
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                screen: false,
                button: t('pdf.button'),
                pdf: "",
                pdfThumbnail:'',
                website: "www.bestbusinessbank.com",
                defImg: "/pdf.jpg"
            },
            links:{
                list_title: 'Carla Rasmussen',
                list_description:  'Follow all of my social media channels for foodies, and join me as I dine around the world!',
                font_text: "GT Walsheim Pro",
                font_title: "GT Walsheim Pro",
                primaryColor: "#F56B4D",
                companyLogo: "/images/images/new/v4/links/carla.png",
                screen: false,
                linkList: [
                    {
                        name: 'Carla’s Favorite Recipes',
                        image: "/images/images/new/v4/links/carla.png",
                        link: ""
                    },
                    {
                        name: "My Cooking Recipe",
                        image: "/images/images/new/v4/links/cooking.png",
                        link: ""
                    },
                    {
                        name: "Carla’s TikTok Profile",
                        image: "/images/images/new/v2/links/tiktok.png",
                        link: ""
                    },
                    {
                        name: "Spain Food Tour",
                        image: "/images/images/new/v2/links/youtube.png",
                        link: ""
                    },
                ],
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'Facebook',
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'Instagram',
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'Snapchat',
                        url: "/images/social/snapchat.png"
                    },
                    
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'LinkedIn',
                        url: "/images/social/linkedin-full.png"
                    },
                    
                ]
            },
            vcard:{
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                vcard_profession: "CEO",
                primaryColor: "#65B8BA",
                vcard_first_name: "James Hall",
                vcard_last_name: "",
                socialLinks: [
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: t('vcard.facebook'),
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: 'Telegram',
                        url: "/images/social/telegram.png"
                    },
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: 'LinkedIn',
                        url: "/images/social/linkedin-full.png"
                    },
                    
                    {
                        name: "",
                        text: t('vcard.social_account'),
                        icon: "Instagram",
                        url: "/images/social/instagram.webp"
                    },
                ],
                vcard_website: [
                    {
                        title: t('vcard.website'),
                        website: "www.hallsoftware.com"
                    }
                ],
                vcard_phone: [
                    {
                        label: "",
                        type: "other",
                        number: "(000) 555-1010"
                    }
                ],
                vcard_email: [
                    {
                        title: "",
                        email: "james@hallsoftware.com"
                    }
                ],
                vcard_note: "I am the founder and owner of Hall of Fame Software Development. Contact us to discover our innovative solutions!",
                vcard_company: "Hall of Fame Software Development",
                vcard_social_label: [],
                vcard_social_value: [],
                images: "/images/images/new/v4/vcard_avatar.webp",
                offer_street: "4387 Main St San Antonio, TX 78212",
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                vcard_add_contact_at_top: 'falseprev',
                street_number: true
            },
            business:{
                contactMobiles: [
                    {
                        title: "",
                        number: "(000) 555-1020"
                    }
                ],
                contactEmails: [
                    {
                        title: "",
                        email: "carl@monamicoffee.com"
                    }
                ],
                contactWebsite:[
                    {
                        title:'',
                        website:'www.monamicoffee.com'
                    }
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'Google',
                        url: "/images/social/google.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: 'Uber Eats',
                        url: "/images/social/uber.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "DoorDash",
                        url: "/images/social/doordash.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.facebook'),
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: "Portfolio designs",
                        icon: "Google Review",
                        url: "/images/social/google-review.png"
                    },
                ],
                aboutCompany: "We slow-roast our espresso beans on-site and bake our delicious pastries fresh daily!",
                weekDays: {
                    Monday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        },
                        {
                            "from": "17:00",
                            "to": "20:00"
                        },
                    ],
                    Tuesday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Wednesday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Thursday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Friday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                    Saturday: [
                        {
                            "from": "10:00",
                            "to": "17:00"
                        }
                    ],
                },
                primaryColor: "#78453E",
                SecondaryColor: "#78453E",
                companyLogo: "/images/images/new/v4/business.webp",
                company: "Try Our Pastries and Coffee!",
                companyTitle: "Mon Ami Coffee ",
                companySubtitle: "We roast our coffee beans and bake our pastries in-house!",
                screen: false,
                contactName: "Carl Thomas",
                businessButtons: "Order Now",
                businessButtonUrls: "#",
                businessButtonsCreated: "0",
                cardContainer: false,
                ship_address: "",
                offer_street: '4230 1st Ave N New Orleans, LA 70115',
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                ficons: [
                    "ficon",
                    "ficon1",
                    "ficon2",
                    "ficon3",
                    "ficon4",
                    "ficon5",
                    "ficon6",
                    "ficon7",
                    "ficon8",
                    "ficon9",
                    "ficon10",
                    "ficon11",
                    // "ficon12",
                    // "ficon13",
                    // "ficon14",
                ],
                street_number: true
            },
            images:{
                primaryColor: "#E1810B",
                image_title: "Headshot Photography",
                image_description: "Book our photography services today to get the best headshots in the region!",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                screen: false,
                website: "www.photographyusa.com",
                images: [
                    "/images/images/new/v4/image1.webp",
                    "/images/images/new/v4/image2.webp",
                ],
                buttons: [
                    {
                        text: 'View Gallery',
                        url: "#"
                    }
                ],
                horizontal: true
            },
            video:{
                videos: [],
                direct_video: false,
                Highlight: false,
                Autoplay: false,
                primaryColor: "#FF8857",
                companyName: "Sophia's Studio",
                videoTitle: "Art & Design Class",
                videoDescription: "Learn techniques for everything from graphic design to watercolor painting and so much more!",
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                button_text: "Learn More",
                button_url: "",
                socialLinks: [
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "Pinterest",
                        url: "/images/social/pinterest.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "TikTok",
                        url: "/images/social/tiktok.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('links.social.youtube'),
                        url: "/images/social/youtube.svg"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: t('bushiness.instagram'),
                        url: "/images/social/instagram.webp"
                    },
                ],
            },
            app:{
                screen: false,
                SecondaryColor: "#17B961",
                primaryColor: "#17B961",
                app_title: "GreenPlate Fresh Eating",
                app_description: "GreenPlate Fresh Eating is your one-stop shop for tasty recipes and diet plans!",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                app_website: "www.greenplate.com",
                app_company: "Sustainable Eating App",
                google: "#",
                apple: "#",
                amazon: "#",
                images: [],
                companyLogo: "/images/images/new/v4/app.png",
            },
            coupon:{
                screen: false,
                newShareIcon: true,
                ship_address: "",
                company: "Discount Cookware",
                title: "50% OFF",
                description: "Get a generous discount on any pots and pans in our online store, for a limited time only!",
                salesBadge: "50% OFF",
                buttonToSeeCode: "Get Discount",
                couponCode: "20%%20OFF",
                validTillDate: "",
                terms: [t('coupon.termsParagraph')],
                buttonText: t('coupon.webButton'),
                buttonUrl: "#",
                primaryColor: "#FA9794",
                SecondaryColor: "#FA8794",
                ThirdColor: "#000000",
                companyLogo: "/images/images/new/v4/coupon.webp",
                offerImage: "https://t3.ftcdn.net/jpg/02/55/97/94/360_F_255979498_vewTRAL5en9T0VBNQlaDBoXHlCvJzpDl.jpg",
                offer_street: "",
                offer_number: "",
                offer_postalcode: "",
                offer_city: "",
                offer_state: "",
                offer_country: "",
                offer_url1: "#",
                latitude: "",
                longitude: "",
                street_number: false,
                flip: false,
                barCodeTgl: true,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro"
            },
            mp3:{
                screen: false,
                addDownloadOption: "",
                button_url: "",
                button_text: "Listen Now!",
                primaryColor: "#680369",
                SecondaryColor: "#680369",
                mp3_title: "The Daily Grind",
                mp3_description: "Kathy Bartlett",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                mp3_website: "www.kathybartlett.com",
                mmp3: [
                    "/empty.mp3"
                ],
                images: [],
                image: "/images/images/new/v4/mp3.webp",
                button_created: "0",
                socialLinks: [ 
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "Soundcloud",
                        url: "/images/social/soundcloud.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "Spotify",
                        url: "/images/social/spotify.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "Apple Music",
                        url: "/images/social/apple-music.png"
                    },
                    {
                        name: "",
                        text: t('links.socialAccType'),
                        icon: "YouTube",
                        url: "/images/images/new/v2/links/youtube.png"
                    },
                ],
                socialLinksTitle: "Find me on"
            },
            event:{
                screen: false,
                primaryColor: "#0060CA",
                title: "Midwest Cyber Summit",
                description: "Learn from the experts how to protect your business from cybersecurity risks!",
                image: "/images/images/new/v4/event.webp",
                buttonText: "View More",
                buttonUrl: "#",
                dates: {
                    startDateText: "Tuesday, November 19, 2024",
                    startDate: "2024-07-06",
                    startTime: "09:00",
                    endDateText: "Wednesday, November 20, 2024",
                    endDate: "2024-07-07",
                    endTime: "18:00",
                    timeZone: "Africa/Cairo",
                    preview: true
                },
                aboutEvent: "Join us for an all-day conference, discussing all of the most relevant security risks for modern businesses.",
                location: {
                    street: "1269 Main St Fargo",
                    st_number: "",
                    postalcode: "58102",
                    city: "",
                    state: "ND",
                    country: "",
                    url: "#",
                    latitude: "",
                    longitude: "",
                    street_number: true,
                },
                ficons: [
                    "ficon",
                    "ficon1",
                    "ficon2",
                    "ficon3",
                    "ficon4",
                    "ficon5",
                    "ficon6",
                    "ficon7",
                    "ficon8",
                    "ficon9",
                    "ficon10",
                    "ficon11",
                    // "ficon12",
                    // "ficon13",
                    // "ficon14",
                ],
                contactName: "Kimberly Masters",
                websites: [
                    {
                        title: "Website",
                        website: "www.midwestcybersummit.com"
                    },
                ],
                phones: [
                    {
                        label: "Phone",
                        type: "other",
                        number: "(000) 555-1070"
                    },
                ],
                emails: [
                    {
                        title: "Email",
                        email: "kimberly@midwestcyber.com"
                    }
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
            },
            email:{
                screen: false,
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                primaryColor: "#2E836E",
                title: "Harbor View Realty",
                first_name: "Harbor View Realty",
                last_name: "",
                description: "Contact us for property inquiries or rental services. We're here to assist you!",
                image: "/images/images/new/v4/email-bg.png",
                websites: [
                    {
                        title: "Website",
                        website: "harborviewrealty.com"
                    },
                ],
                phones: [
                    {
                        label: "Phone",
                        type: "other",
                        number: "(001) 555-0404"
                    },
                ],
                emails: [
                    {
                        title: "Email",
                        email: "info@harborviewrealty.com"
                    }
                ],
                buttons : [
                    {
                        title: "Sales Department",
                        email: "#",
                        subject: "",
                        body: "",
                        icon: "email",
                    },
                    {
                        title: "Rental Services",
                        email: "#",
                        subject: "",
                        body: "",
                        icon: "icon",
                    },
                ],
            },
            review:{
                screen: false,
                primaryColor: "#14B4DE",
                type: "both", //public, private or both
                publicReviewType: {
                    image: "/images/images/new/google.svg",
                    title: "Google Rating",
                },
                emailInput: true,
                phoneInput: true,
                title: "Venice Beach Snacks",
                image: "/images/images/new/v4/review.webp",
                website: "www.venicesnacks.com",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
            },
            social:{
                screen: false,
                primaryColor: "#8A384C",
                images: [
                    "/images/images/new/v4/socialMedia/1.webp",
                    "/images/images/new/social media/2.webp",
                    "/images/images/new/social media/3.webp",
                ],
                title: "Sally Flores",
                description: "I’m Sally — welcome to my social media hub! Click the links below to connect and interact with me on all of my channels!",
                socialLinks: [
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "TikTok",
                        url: "/images/social/tiktok.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Facebook",
                        url: "/images/social/facebook.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Instagram",
                        url: "/images/social/instagram.webp"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Snapchat",
                        url: "/images/social/snapchat.png"
                    },
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                horizontal: false
            },
            facebook:{
                screen: false,
                cover: "/images/images/new/v4/fb_cover.webp",
                profileImg: "/images/images/new/fb_pic.webp",
                title: 'Influencers & Motivators',
                description: 'Learn from social media’s most inspiring influencers! The Influencers & Motivators Facebook group includes helpful tips, tricks of the trade, and a great community!',
                pageUrl: "#",
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
            },
            sms:{
                number: "+123 456 7890",
                message: "",
                primaryColor: "#147EFB"
            },
            menu:{
                roundedLogo: true,
                sections: [
                    {
                        id: "1",
                        name: "Appetizers",
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Caprese Skewers",
                                image: "/images/images/new/v4/menu/caprese-skewers.webp",
                                translated: "Spiedini Caprese",
                                description: "Skewers of cherry tomatoes, fresh mozzarella, and basil leaves, drizzled with balsamic reduction.",
                                price: "$7",
                                allergens: [
                                    "soy",
                                ]
                            }
                        ]
                    },
                    {
                        id: "8",
                        name: "Drinks",
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Lemon Basil Iced Tea",
                                image: "/images/images/new/v4/menu/lemon-basil-iced-tea.webp",
                                translated: "Tè Freddo al Limone e Basilico",
                                description: "Refreshing iced tea infused with fresh lemon juice and aromatic basil leaves.",
                                price: "$6",
                                allergens: [
                                    "cereals",
                                ]
                            }
                        ]
                    },
                    {
                        id: "78",
                        name: "Main Courses",
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Fish Piccata",
                                image: "/images/images/new/v4/menu/fish-piccata.webp",
                                translated: "Pesce Piccata",
                                description: "Tender fish fillets lightly coated in flour and pan-seared, then served with a tangy lemon-butter caper sauce. Accompanied by a side of roasted vegetables.",
                                price: "$26",
                                allergens: [
                                    "fish",
                                    "molluscs",
                                    "sesame",
                                ]
                            }
                        ]
                    },
                    {
                        id: "139",
                        name: t('qrc_io.menu.section_4'),
                        description: "",
                        products: [
                            {
                                id: "1",
                                name: "Tiramisu",
                                image: "/images/images/new/v4/menu/tiramisu.webp",
                                translated: "Tiramisù",
                                description: "Italian dessert with layers of coffee-soaked ladyfingers, mascarpone cheese, and cocoa powder.",
                                price: "$9",
                                allergens: [
                                    "milk",
                                    "eggs",
                                    "peanuts",
                                ]
                            }
                        ]
                    }
                ],
                socialLinks: [
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Google",
                        url: "/images/social/google-transparent.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "DoorDash",
                        url: "/images/social/doordash.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Uber Eats",
                        url: "/images/social/uber.png"
                    },
                    {
                        name: "",
                        text: t('social.account'),
                        icon: "Facebook",
                        url: "/images/social/facebook.png"
                    },
                    
                ],
                contactMobiles: [
                    {
                        title: "",
                        number: "(000) 555-1040"
                    }
                ],
                contactEmails: [
                    {
                        title: "",
                        email: "Carl@chefsplace.com"
                    }
                ],
                activeId: "",
                contactName: "Carl Rossi",
                contactWebsite:[
                    {
                        title:'',
                        website:'www.chefsplace.com'
                    }
                ],
                font_title: "GT Walsheim Pro",
                font_text: "GT Walsheim Pro",
                primaryColor: "#1CA0EB",
                companyLogo: "/images/images/new/v4/menu.png",
                companyTitle: "Chef’s Place",
                companyDescription: "Italy’s Best Cuisine!",
                screen: false,
                weekDays: {
                    Monday: [
                        {
                            from: "10:00",
                            to: "14:00"
                        },
                        {
                            from: "17:00",
                            to: "23:00"
                        },
                    ],
                    Tuesday: [
                        {
                            from: "10:00",
                            to: "20:00"
                        }
                    ],
                    Wednesday: [
                        {
                            from: "11:00",
                            to: "19:00"
                        }
                    ],
                    Thursday: [
                        {
                            from: "11:00",
                            to: "19:00"
                        }
                    ],
                    Friday: [
                        {
                            from: "11:00",
                            to: "19:00"
                        }
                    ],
                    Saturday: [
                        {
                            from: "11:00",
                            to: "19:00"
                        }
                    ],
                },
                areAllDaysNull: false
            },
            instagram:{
                username: t('instagram.username'),
                live: true
            },
            whatsapp:{
                number: "+123 456 789",
                message: "",
                live: true
            },
        },
    }
}
