import {React} from "react";
import "../index.css";
import useLanguage from "../../../utils/useLanguage";
import useUpdateDomTitle from "../../../utils/updateDomTitle";

let Whatsapp4Preview = (props) => {
    const t = useLanguage();
    useUpdateDomTitle('Online QR Generator - Scanned Page | Whatsapp',"#008069");
    return (
        <div className="flex flex-col justify-center items-center" >
            <div className="flex flex-col items-center justify-center w-full max-w-[700px] min-h-screen h-screen">
                <div className="flex items-end justify-between w-full h-20 px-[15px] bg-[#008069] pb-3">
                    <div className="flex flex-row items-center justify-between  w-full">
                        <div className="flex flex-row justify-between items-center space-x-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="rtl:ml-2 rtl:rotate-180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 12.2739C20 12.6536 19.7178 12.9674 19.3518 13.0171L19.25 13.0239L4.25 13.0239C3.83579 13.0239 3.5 12.6881 3.5 12.2739C3.5 11.8942 3.78215 11.5804 4.14823 11.5308L4.25 11.5239L19.25 11.5239C19.6642 11.5239 20 11.8597 20 12.2739Z" fill="white"/>
                                <path d="M10.829 17.767C11.1225 18.0593 11.1235 18.5342 10.8313 18.8277C10.5656 19.0945 10.149 19.1196 9.85489 18.9024L9.77062 18.83L3.72062 12.806C3.45298 12.5395 3.42863 12.1214 3.64759 11.8273L3.72057 11.7431L9.77057 5.71808C10.0641 5.4258 10.5389 5.42678 10.8312 5.72028C11.0969 5.9871 11.1203 6.40381 10.9018 6.69697L10.829 6.78094L5.313 12.2748L10.829 17.767Z" fill="white"/>
                            </svg>
                            <div className="h-9 w-9 rounded-full" style={{backgroundColor:"#12BB18", backgroundImage: "url(/images/images/new/whatsapp.png)", backgroundSize: "cover"}}></div>
                        </div>
                        <div className="flex flex-row justify-between items-center space-x-3 rtl:space-x-2">
                            
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_3354_14151" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="2" y="5" width="17" height="16">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.76562 5.36719H18.2659V20.8685H2.76562V5.36719Z" fill="white"/>
                                </mask>
                                <g mask="url(#mask0_3354_14151)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.61273 6.86853C7.17373 6.86853 6.89073 6.86853 6.84173 6.87053C6.10373 6.90153 5.42273 7.21453 4.93173 7.75053C4.47373 8.25153 4.23773 8.89153 4.26873 9.55553C4.26873 12.4485 4.26973 16.7905 4.27673 17.0285C4.39073 18.4135 5.68873 19.4725 7.17473 19.3585C9.92473 19.3625 13.9987 19.3715 14.1897 19.3645C14.9247 19.3345 15.6067 19.0225 16.0977 18.4875C16.5567 17.9885 16.7927 17.3485 16.7637 16.6875C16.7627 16.6765 16.7627 16.6655 16.7627 16.6545C16.7637 13.7945 16.7637 9.45553 16.7567 9.22253C16.6437 7.83053 15.3397 6.75853 13.8457 6.87553C11.7797 6.87253 8.96073 6.86853 7.61273 6.86853ZM6.91173 20.8685C4.79773 20.8695 2.95373 19.2495 2.77973 17.1245C2.77673 17.0835 2.76673 16.9585 2.76973 9.58953C2.72173 8.55853 3.09573 7.53453 3.82573 6.73753C4.58773 5.90553 5.63873 5.42053 6.78573 5.37153C6.92473 5.36153 11.7327 5.37353 13.7917 5.37753C16.0417 5.21953 18.0727 6.88953 18.2537 9.13053C18.2617 9.23953 18.2647 11.7645 18.2627 16.6395C18.3047 17.6955 17.9287 18.7125 17.2027 19.5025C16.4397 20.3335 15.3877 20.8165 14.2417 20.8635C14.1017 20.8735 9.29173 20.8605 7.22973 20.8565C7.12373 20.8645 7.01773 20.8685 6.91173 20.8685Z" fill="white"/>
                                </g>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5835 18.7165C21.1325 18.7165 20.6875 18.5615 20.3205 18.2605L17.0395 15.5645C16.7195 15.3015 16.6735 14.8285 16.9365 14.5085C17.1985 14.1895 17.6715 14.1415 17.9915 14.4055L21.2715 17.1015C21.4925 17.2815 21.7145 17.2075 21.8005 17.1655C21.8865 17.1255 22.0855 17.0005 22.0855 16.7165L22.0975 9.3675C22.0985 9.0835 21.8985 8.9585 21.8125 8.9175C21.7275 8.8775 21.5025 8.8025 21.2835 8.9825L17.9905 11.6775C17.6685 11.9405 17.1975 11.8935 16.9355 11.5715C16.6725 11.2515 16.7205 10.7795 17.0405 10.5175L20.3335 7.8215C20.9375 7.3265 21.7505 7.2295 22.4555 7.5625C23.1605 7.8975 23.5985 8.5895 23.5975 9.3695L23.5855 16.7185C23.5845 17.4985 23.1455 18.1905 22.4415 18.5225C22.1655 18.6535 21.8735 18.7165 21.5835 18.7165Z" fill="white"/>
                            </svg>

                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.05434 4.45504L4.12689 4.37922C6.05431 2.45163 7.30947 1.9407 8.64869 2.71081C9.03393 2.93234 9.39508 3.24242 9.88617 3.7412L11.3925 5.30322C12.2246 6.21112 12.4137 7.08197 12.1428 8.10184L12.1055 8.23686L12.064 8.37111L11.8627 8.96193C11.4316 10.2869 11.6116 11.0345 13.1451 12.5676C14.7398 14.1619 15.4842 14.2923 16.9114 13.7936L17.1657 13.7052L17.4733 13.6053L17.6079 13.5679C18.6919 13.2787 19.6077 13.5095 20.5788 14.48L21.7926 15.6524L22.1498 16.0038C22.5459 16.4077 22.8077 16.7278 23.0011 17.0661C23.7664 18.4049 23.255 19.6593 21.2719 21.6349L21.0833 21.8261C20.7872 22.1114 20.5106 22.3158 20.1042 22.5088C19.4221 22.8326 18.6162 22.9548 17.6798 22.8245C15.3724 22.5036 12.4458 20.683 8.7375 16.9756C8.43564 16.6739 8.14653 16.3779 7.86987 16.0877L7.3331 15.5148C2.30325 10.05 1.85951 6.69307 3.9208 4.58769L4.05434 4.45504ZM8.67204 4.64811C8.34624 4.32692 8.10959 4.13112 7.90094 4.01114C7.43942 3.74575 6.94112 3.85793 6.01892 4.65166L5.72915 4.91053C5.67861 4.95719 5.62691 5.00562 5.57401 5.05587L5.24173 5.3795L5.2118 5.41691L4.98653 5.64322C4.44218 6.19923 4.18433 6.87782 4.40653 8.04486C4.77114 9.95988 6.44004 12.5577 9.79803 15.9148C13.2969 19.4128 15.9628 21.0713 17.8864 21.3388C19.0078 21.4948 19.5798 21.2232 20.1632 20.6256L20.608 20.1767C20.8166 19.9584 20.9949 19.7605 21.1454 19.5798L21.3507 19.3211C21.8753 18.6223 21.9232 18.203 21.6989 17.8105C21.6135 17.6611 21.4893 17.4974 21.3085 17.2972L21.0633 17.0382L20.9182 16.893L19.3869 15.4144C18.8752 14.9425 18.5255 14.8756 17.9946 15.0172L17.8413 15.0613L17.207 15.2766C15.3669 15.8709 14.0394 15.5827 12.0846 13.6284C10.0599 11.6042 9.82247 10.2526 10.5008 8.30636L10.5444 8.1813L10.6647 7.81872L10.7239 7.58762C10.8288 7.08465 10.709 6.73396 10.1683 6.19319C10.1461 6.17093 10.1212 6.14585 10.0941 6.11839L8.67204 4.64811Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="flex-1 w-full relative" style={{backgroundImage: "url(/images/images/new/whatsapp-background.webp)", backgroundSize: "cover"}}>
                    <div className="w-full flex flex-col mt-2 xsm:mt-3 sm:mt-5 space-y-4 xsm:space-y-8 sm:space-y-14">
                        <div className="w-full flex items-center justify-center">
                            <span className="bg-[#F5F3EF] w-fit px-5 py-1 rounded-3xl text-[#767C83] text-xs">Today</span>
                        </div>
                        <div className="w-full flex justify-start pl-5">
                            <div className="bg-[#FAFAFA] w-[80%] flex flex-col items-start px-3 py-5 space-y-3 rounded-[6px]">
                                <div className="w-full h-[7px] rounded-full animate-pulse bg-[#1A1D1C26]"></div>
                                <div className="w-[80%] h-[7px] rounded-full animate-pulse bg-[#1A1D1C26]"></div>
                                <div className="w-[60%] h-[7px] rounded-full animate-pulse bg-[#1A1D1C26]"></div>
                            </div>
                        </div>
                        <div className="w-full flex flex-col items-end space-y-2 pr-5">
                            <div className="bg-[#E1FFD3] py-3 pl-3 pr-7 w-[60%] rounded-lg relative rtl:scale-x-[-1]">
                                <div className="w-full bg-[#ADC4A2] h-[7px] animate-pulse rounded-md"></div>
                                <svg className="absolute right-2 bottom-2" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.9903 0.474189L14.4893 0.960964L9.08968 8.36523L6.90091 6.20358L7.82783 4.93168L9.08968 5.66762L13.9903 0.474189ZM9.60851 0.365234L10.1075 0.852009L4.7079 8.25628L0.989258 4.59881L1.81959 3.78878L4.7079 5.55867L9.60851 0.365234Z" fill="#D3D4D9"/>
                                </svg>
                            </div>
                            <div className="bg-[#E1FFD3] py-3 pl-3 pr-7 w-3/4 rounded-lg relative rtl:scale-x-[-1]">
                                <div className="w-full bg-[#ADC4A2] h-[7px] animate-pulse rounded-md"></div>
                                <svg className="absolute right-2 bottom-2" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.9903 0.474189L14.4893 0.960964L9.08968 8.36523L6.90091 6.20358L7.82783 4.93168L9.08968 5.66762L13.9903 0.474189ZM9.60851 0.365234L10.1075 0.852009L4.7079 8.25628L0.989258 4.59881L1.81959 3.78878L4.7079 5.55867L9.60851 0.365234Z" fill="#D3D4D9"/>
                                </svg>
                            </div>
                        </div>
                        <div className="w-full flex justify-start pl-5">
                            <div className="bg-[#FAFAFA] py-3 px-5 w-3/4 space-y-3 rounded-lg relative rtl:scale-x-[-1]">
                                <div className="w-[80%] bg-[#DBDBDB] h-[7px] animate-pulse rounded-md"></div>
                                <div className="w-[60%] bg-[#DBDBDB] h-[7px] animate-pulse rounded-md"></div>
                            </div>
                        </div>

                        <div className="w-full flex flex-col items-end space-y-2 pr-5 relative">
                            <div className="bg-[#E1FFD3] py-3 pl-3 pr-7 w-3/4 space-y-3 rounded-lg relative rtl:scale-x-[-1]">
                                <div className="w-full bg-[#ADC4A2] h-[7px] animate-pulse rounded-md"></div>
                                <div className="w-[75%] bg-[#ADC4A2] h-[7px] animate-pulse rounded-md"></div>
                                <div className="w-full bg-[#ADC4A2] h-[7px] animate-pulse rounded-md"></div>
                                <div className="w-[75%] bg-[#ADC4A2] h-[7px] animate-pulse rounded-md"></div>
                            </div>
                            <svg className="absolute right-7 bottom-2" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.9903 0.474189L14.4893 0.960964L9.08968 8.36523L6.90091 6.20358L7.82783 4.93168L9.08968 5.66762L13.9903 0.474189ZM9.60851 0.365234L10.1075 0.852009L4.7079 8.25628L0.989258 4.59881L1.81959 3.78878L4.7079 5.55867L9.60851 0.365234Z" fill="#D3D4D9"/>
                            </svg>
                        </div>
                    </div>

                    <div className="bg-white flex space-x-1 w-full pt-3 pb-8 xxs:px-[10px] px-[15px] absolute bottom-0">
                        <div className="bg-white  flex-1 rounded-full space-x-2 flex items-center pl-2 pr-4 rtl:ml-2">
                            <div className="flex items-center justify-between space-x-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.5938 10.9375H1.09375C0.495833 10.9375 0 10.4417 0 9.84375C0 9.24583 0.495833 8.75 1.09375 8.75H18.5938C19.1917 8.75 19.6875 9.24583 19.6875 9.84375C19.6875 10.4417 19.1917 10.9375 18.5938 10.9375Z" fill="#007BFF"/>
                                    <path d="M9.84375 19.6875C9.24583 19.6875 8.75 19.1917 8.75 18.5938V9.84375V1.09375C8.75 0.495833 9.24583 0 9.84375 0C10.4417 0 10.9375 0.495833 10.9375 1.09375V18.5938C10.9375 19.1917 10.4417 19.6875 9.84375 19.6875Z" fill="#007BFF"/>
                                </svg>
                            </div>
                            <div className="flex flex-1 border-2 py-[6px] border-[#00000010] rounded-full items-center justify-end px-2 space-x-2">
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0156 17.9473H7.51562C3.44313 17.9473 1.70312 16.2073 1.70312 12.1348V7.63477C1.70312 3.56227 3.44313 1.82227 7.51562 1.82227H11.2656C11.5731 1.82227 11.8281 2.07727 11.8281 2.38477C11.8281 2.69227 11.5731 2.94727 11.2656 2.94727H7.51562C4.05813 2.94727 2.82812 4.17727 2.82812 7.63477V12.1348C2.82812 15.5923 4.05813 16.8223 7.51562 16.8223H12.0156C15.4731 16.8223 16.7031 15.5923 16.7031 12.1348V8.38477C16.7031 8.07727 16.9581 7.82227 17.2656 7.82227C17.5731 7.82227 17.8281 8.07727 17.8281 8.38477V12.1348C17.8281 16.2073 16.0881 17.9473 12.0156 17.9473Z" fill="#007BFF"/>
                                    <path d="M17.2656 8.94811H14.2656C11.7006 8.94811 10.7031 7.95061 10.7031 5.38561V2.38561C10.7031 2.16061 10.8381 1.95061 11.0481 1.86811C11.2581 1.77811 11.4981 1.83061 11.6631 1.98811L17.6631 7.98811C17.8206 8.14561 17.8731 8.39311 17.7831 8.60311C17.6931 8.81311 17.4906 8.94811 17.2656 8.94811ZM11.8281 3.74311V5.38561C11.8281 7.32061 12.3306 7.82311 14.2656 7.82311H15.9081L11.8281 3.74311Z" fill="#007BFF"/>
                                </svg>
                            </div>
                        </div>
                        <div className="space-x-2 flex items-center justify-center">
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.68601 5.42936C8.08796 4.69883 8.52522 4.21049 9.17407 3.95223C10.3631 3.47945 14.6215 3.47945 15.8095 3.95232C16.458 4.21044 16.8955 4.6987 17.2977 5.42908L17.7499 6.29623C17.7603 6.31341 17.7698 6.32852 17.7783 6.34152L17.8167 6.39403C18.0228 6.61853 18.3676 6.75416 18.5952 6.75416C20.7472 6.75416 22.4922 8.49875 22.4922 10.6502V16.4452C22.4922 19.1894 20.2664 21.4152 17.5222 21.4152H7.46219C4.71729 21.4152 2.49219 19.1897 2.49219 16.4452V10.6502C2.49219 8.49875 4.23717 6.75416 6.38919 6.75416C6.61596 6.75416 6.96127 6.61833 7.16763 6.39403C7.17175 6.38954 7.17713 6.38285 7.18363 6.37399L7.23506 6.29511L7.68601 5.42936ZM15.9838 6.15274C15.7339 5.6989 15.508 5.44675 15.2548 5.34599L15.1355 5.30632C14.1064 5.01544 10.5266 5.02866 9.72854 5.34599C9.47552 5.44669 9.24987 5.69871 9.00021 6.15246L8.61535 6.89656L8.52062 7.06799C8.44155 7.19952 8.36245 7.31054 8.27214 7.40895C7.76567 7.95945 7.01644 8.25416 6.38919 8.25416L6.22509 8.25969C4.97794 8.34401 3.99219 9.38237 3.99219 10.6502V16.4452C3.99219 18.3613 5.54577 19.9152 7.46219 19.9152H17.5222C19.438 19.9152 20.9922 18.3609 20.9922 16.4452V10.6502C20.9922 9.32725 19.9189 8.25416 18.5952 8.25416L18.4211 8.24688C17.8325 8.19845 17.1715 7.90932 16.7116 7.40828C16.6225 7.31118 16.5438 7.20094 16.4652 7.07053C16.454 7.05182 16.4399 7.02719 16.4238 6.99804L16.3077 6.7803L15.9838 6.15274Z" fill="#007BFF"/>
                                <path d="M17.9973 9.09766C18.5496 9.09766 18.9973 9.54537 18.9973 10.0977C18.9973 10.6105 18.6112 11.0332 18.1139 11.0909L17.9973 11.0977C17.436 11.0977 16.9883 10.6499 16.9883 10.0977C16.9883 9.58482 17.3743 9.16215 17.8717 9.10438L17.9973 9.09766Z" fill="#007BFF"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5625 13.7259C8.5625 11.5557 10.3213 9.79688 12.4915 9.79688C14.6617 9.79688 16.4205 11.5557 16.4205 13.7259C16.4205 15.8961 14.6617 17.6549 12.4915 17.6549C10.3213 17.6549 8.5625 15.8961 8.5625 13.7259ZM14.9205 13.7259C14.9205 12.3841 13.8333 11.2969 12.4915 11.2969C11.1497 11.2969 10.0625 12.3841 10.0625 13.7259C10.0625 15.0677 11.1497 16.1549 12.4915 16.1549C13.8333 16.1549 14.9205 15.0677 14.9205 13.7259Z" fill="#007BFF"/>
                            </svg>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2422 23.0985C11.8282 23.0985 11.4922 22.7625 11.4922 22.3485V19.1875C11.4922 18.7735 11.8282 18.4375 12.2422 18.4375C12.6562 18.4375 12.9922 18.7735 12.9922 19.1875V22.3485C12.9922 22.7625 12.6562 23.0985 12.2422 23.0985Z" fill="#007BFF"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2417 3.09766C10.4157 3.09766 8.92969 4.59166 8.92969 6.42866V11.1167C8.92969 12.9517 10.4157 14.4467 12.2417 14.4467C14.0677 14.4467 15.5537 12.9517 15.5537 11.1167V6.42866C15.5537 4.59166 14.0677 3.09766 12.2417 3.09766ZM12.2417 15.9467C9.58869 15.9467 7.42969 13.7797 7.42969 11.1167V6.42866C7.42969 3.76466 9.58869 1.59766 12.2417 1.59766C14.8947 1.59766 17.0537 3.76466 17.0537 6.42866V11.1167C17.0537 13.7797 14.8947 15.9467 12.2417 15.9467Z" fill="#007BFF"/>
                                <mask id="mask0_3354_14205" style={{maskType : "luminance"}} maskUnits="userSpaceOnUse" x="3" y="10" width="18" height="10">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.49219 10.3984H20.9917V19.9364H3.49219V10.3984Z" fill="white"/>
                                </mask>
                                <g mask="url(#mask0_3354_14205)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2422 19.9364C7.41719 19.9364 3.49219 15.9944 3.49219 11.1484C3.49219 10.7344 3.82819 10.3984 4.24219 10.3984C4.65719 10.3984 4.99219 10.7344 4.99219 11.1484C4.99219 15.1664 8.24419 18.4364 12.2422 18.4364C16.2402 18.4364 19.4922 15.1664 19.4922 11.1484C19.4922 10.7344 19.8282 10.3984 20.2422 10.3984C20.6572 10.3984 20.9922 10.7344 20.9922 11.1484C20.9922 15.9944 17.0672 19.9364 12.2422 19.9364Z" fill="#007BFF"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
};

export default Whatsapp4Preview;

