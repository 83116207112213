import {React, useEffect, useState, useCallback} from "react";
import "./index.css";
// import useLanguage from "../../../utils/useLanguage";
import ImageViewer from "react-simple-image-viewer";
import AOS from "aos";
import "aos/dist/aos.css";
import useLanguage from "../../../../utils/useLanguage";
import useUpdateDomTitle from "../../../../utils/updateDomTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import WelcomeScreen from "../../../common/WelcomeScreen";
import ShareOrCopy from "../../../../utils/share";
import SocialNetWorks from "../../../common/socialNetwork";
import useScroller from "../../../../utils/Scroller";
import { isLight } from "../../../../utils/Color";

let Social = ({ qrData }) => {
  const data = qrData
  const t = useLanguage();

  useUpdateDomTitle(data?.title,data?.primaryColor);
  const [welcomeScreen, showWelcomeScreen] = useState(false);

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [backdropHeight, setBackdropHeight] = useState(275);
  const [isImageViewerShow, setIsImageViewerShow] = useState(false);
  let [imgIndex, setimgIndex] = useState(0);

  useEffect(()=>{

    let elementHeight = 0;
    const titleElement = document.querySelector("#title");
    const additionalHeight = window.innerWidth > 298 ? 180 : 100;

    if (titleElement) {
      elementHeight = titleElement.getBoundingClientRect().height + additionalHeight;
    }

    setBackdropHeight(elementHeight);
    
  },[data?.title])

  const closeImageViewer = () => {
    setCurrentImage(null);
    setIsViewerOpen(false);
  };

  const handleClick = (direction, totalImages, e) => {
    setIsImageViewerShow(true);

    var currentElement = e.currentTarget;
    const parentElement = currentElement.parentElement;
    const nextParentElement = parentElement.parentElement;
    const imgElementWrap = nextParentElement.querySelector(".styles-module_content__2jwZj");

    const imgElement = imgElementWrap.querySelector('img');
    const imgSrcValue = imgElement.src;

    let currentImageIndex = totalImages.indexOf(imgSrcValue);

    if(direction === "left"){
      currentImageIndex = (currentImageIndex - 1 + totalImages.length) % totalImages.length;
    }else if(direction === "right"){
      currentImageIndex = (currentImageIndex + 1) % totalImages.length;
    }

    setimgIndex(currentImageIndex);
  };

  useEffect(() =>{
    setIsImageViewerShow(false);
  },[data.images])

  const openImageViewer = useCallback((index) => {

    setIsImageViewerShow(true);
    setimgIndex(index);

    let clickTimer = null;

    if (clickTimer === null) {

      clickTimer = setTimeout(() => {
        setCurrentImage(index);
        setIsViewerOpen(true);
        clickTimer = null;
      }, 100);

    } else {

      clearTimeout(clickTimer);
      clickTimer = null;
      setCurrentImage(index);
      setIsViewerOpen(true);

    }
  }, []);

  useEffect(()=>{
    showWelcomeScreen(data?.screen ? true : false);
  },[data?.screen])

  const ignoredColors = ['#7572FF', '#DE6C7B'];
  const light = isLight(data.primaryColor, ignoredColors);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });

    const d = setTimeout(() => {
      showWelcomeScreen(false);
      data.screen = false;
    }, 3000);

    return ()=>clearTimeout(d);

  }, [data]);

  useScroller(data);

  return welcomeScreen ? (
    <WelcomeScreen screen={data?.screen} />
  ) : (
    <div className="flex flex-col justify-center items-center relative">
      <div 
        className="w-full h-[180px] xs:h-[250px] sm:h-[275px] bg-[#527ac9] -z-10 absolute top-0 duration-500"
        style={{
          backgroundColor: data?.primaryColor || "#527ac9",
          height: data?.images?.length > 0 ? `` : `${backdropHeight}px`
        }}
        id="color_palette"
      ></div>
      <div className="flex flex-col items-center justify-center w-full max-w-[600px] mt-[45px] xsm:mt-[54px]">
        <div className="w-full flex flex-row items-center justify-end px-[20px]">
          <div 
            className={`w-[35px] h-[35px] xs:w-[48px] xs:h-[48px] rounded-[4px] mb-[5px] border-[1px] ${light ? 'border-black/[.18] bg-black/[.15]' : 'border-white/[.18] bg-white/[.15]'} cursor-pointer flex items-center justify-center duration-500`}
            
            onClick={()=>ShareOrCopy(data)}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path 
                d="M7.63118 9.43441C7.05656 8.2874 5.87023 7.5 4.5 7.5C2.567 7.5 1 9.067 1 11C1 12.933 2.567 14.5 4.5 14.5C5.87023 14.5 7.05656 13.7126 7.63118 12.5656M7.63118 9.43441C7.86718 9.90549 8 10.4372 8 11C8 11.5628 7.86718 12.0945 7.63118 12.5656M7.63118 9.43441L14.3688 6.06559M7.63118 12.5656L14.3688 15.9344M14.3688 6.06559C14.9434 7.2126 16.1298 8 17.5 8C19.433 8 21 6.433 21 4.5C21 2.567 19.433 1 17.5 1C15.567 1 14 2.567 14 4.5C14 5.06276 14.1328 5.59451 14.3688 6.06559ZM14.3688 15.9344C14.1328 16.4055 14 16.9372 14 17.5C14 19.433 15.567 21 17.5 21C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14C16.1298 14 14.9434 14.7874 14.3688 15.9344Z" 
                stroke={light ? "black" : "white"} 
                strokeWidth="1.5" 
                strokeLinecap="round"
                className="duration-500"
              />
            </svg>
          </div>
        </div>
        {data?.images?.length < 3 ? (
          <div className="w-full relative xs:p-[10px]">
            {data?.horizontal ? (
              data?.images.map((i, idx) => (
                <div className="flex flex-col items-center justify-center w-full space-y-[10px] xs:space-y-[15px] my-[8px] xs:my-[15px] px-[20px]" key={i}>
                  <img
                    src={i}
                    onClick={()=>openImageViewer(idx)}
                    className="w-full z-10 rounded-[4px] shadow-[1px_6px_30px_rgba(0,31,14,0.05)]"
                    alt=""
                  />
                </div>
              ))
            ):(
              <div className={`swiper-wrap ${data?.images?.length ? 'my-[20px]' : 'my-[0px]'}`}>
                <div className="swiper-container">
                  <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    slidesPerView={"auto"}
                    // loop={true}
                    centeredSlides={true}
                    modules={[EffectCards]}
                    cardsEffect={{
                      rotate: false,
                      perSlideOffset: 18,
                      slideShadows: false,
                    }}
                    initialSlide={0}
                    observer={true}
                    observeSlideChildren={true}
                    observeParents={true}
                    onObserverUpdate={(swiper) => {
                      isImageViewerShow 
                      ? swiper.slideToLoop(imgIndex, 0)
                      : swiper.slideToLoop(0, 0);
                    }}
                    onAfterInit={(swiper) => {swiper.slideToLoop(0, 0)}}
                    className="mySwiper w-full"
                  >
                    {data?.images.map((i, index) => (
                      <SwiperSlide key={index} >
                        <img src={i} alt={`Slide ${i}`} id={index} key={ index } onClick={ () => openImageViewer(index) }/>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            )}
          </div>
        ) : (
          <></> 
        )}
        {data?.images?.length > 2 ? (
          <div className="w-full relative xs:p-[10px]">
            {data?.horizontal ? (
              data?.images.map((i, idx) => (
                <div className="flex flex-col items-center justify-center w-full space-y-[10px] xs:space-y-[15px] my-[8px] xs:my-[15px] px-[20px]" key={i}>
                  <img 
                    src={i}
                    onClick={()=>openImageViewer(idx)}
                    className="w-full z-10 rounded-[4px] shadow-[1px_6px_30px_rgba(0,31,14,0.05)]"
                    alt=""
                  />
                </div>
              ))
            ):(
              <div className={`swiper-wrap ${data?.images?.length ? 'my-[20px]' : 'my-[0px]'}`}>
                <div className="swiper-container">
                  <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    slidesPerView={"auto"}
                    loop={true}
                    centeredSlides={true}
                    modules={[EffectCards]}
                    cardsEffect={{
                      rotate: false,
                      perSlideOffset: 18,
                      slideShadows: false,
                    }}
                    initialSlide={0}
                    observer={true}
                    observeSlideChildren={true}
                    observeParents={true}
                    onObserverUpdate={(swiper) => {
                      isImageViewerShow 
                      ? swiper.slideToLoop(imgIndex, 0)
                      : swiper.slideToLoop(0, 0);
                    }}
                    onAfterInit={(swiper) => {swiper.slideToLoop(0, 200)}}
                    className="mySwiper w-full"
                  >
                    {data?.images.map((i, index) => (
                      <SwiperSlide key={index} >
                        <img src={i} alt={`Slide ${i}`} id={index} key={ index } onClick={ () => openImageViewer(index) } />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            )}
          </div>
        ) : (
          <></> 
        )}
        {isViewerOpen && (
        <div
          style={{
            zIndex: 99,
          }}
        >
          <ImageViewer
            src={ data.images }
            currentIndex={ currentImage }
            disableScroll={true}
            closeOnClickOutside={false}
            onClose={closeImageViewer}
            leftArrowComponent={<ArrowLeft onClick={(e) => handleClick("left", data.images, e)} />}
            rightArrowComponent={<ArrowRight onClick={(e) => handleClick("right", data.images, e)} />}
          />
        </div>
        )}
        <div className="flex flex-col items-center justify-center px-[20px] mb-5 step2 text-pretty w-full">
          {data?.title? (
            <p className={`${light || data?.images?.length ? 'text-black' : 'text-white'} text-base xxs:text-lg xs:text-[24px] sm:text-3xl font-bold mb-4 duration-500 break-all`} style={{fontFamily: data?.font_title}} id="title">{data?.title}</p>
          ):(
            <p className="text-black text-base xxs:text-lg xs:text-[24px] sm:text-3xl font-bold mb-4 duration-500" style={{fontFamily: data?.font_title}} id="title"></p>
          )}
          {data?.description? (
            <p className={`${data?.images?.length ? 'text-[#767C83]' : 'text-black mt-[55px]'} text-[10px] xxs:text-xs xs:text-[16px] sm:text-base mb-4 duration-500 break-words`} style={{fontFamily: data?.font_text}} id="description">{data?.description}</p>
          ):(
            <p className={`${data?.images?.length ? 'text-[#767C83]' : 'text-black mt-[55px]'} text-[10px] xxs:text-xs xs:text-[16px] sm:text-base mb-4 duration-500 break-words`} style={{fontFamily: data?.font_text}} id="description"></p>
          )}
        </div>
        <div className={`mb-[20px] w-full flex flex-col social-wrap items-start justify-center px-[20px] ${data?.images?.length ? 'mt-0' : 'mt-10'}`}>
          <SocialNetWorks data={data} title={t('social.subtitle')}/>
        </div>
      </div>
    </div>
  
  );
};
const ArrowLeft = ({onClick}) => (
  <div 
    className="flex justify-center items-center w-[35px] h-[35px] xs:w-[45px] xs:h-[45px]"
    style={{
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    }}
    onClick={onClick}
  >
      <svg width="28" height="28" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clip-rule="evenodd" d="M11.7071 4.29289C12.0976 4.68342 12.0976 5.31658 11.7071 5.70711L6.41421 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H6.41421L11.7071 18.2929C12.0976 18.6834 12.0976 19.3166 11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071L3.29289 12.7071C3.10536 12.5196 3 12.2652 3 12C3 11.7348 3.10536 11.4804 3.29289 11.2929L10.2929 4.29289C10.6834 3.90237 11.3166 3.90237 11.7071 4.29289Z" fill="#fff"/>
      </svg>
  </div>
);

const ArrowRight = ({onClick}) => (
  <div 
    className="flex justify-center items-center w-[35px] h-[35px] xs:w-[45px] xs:h-[45px]"
    style={{
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    }}
    onClick={onClick}
  >
    <svg width="28" height="28" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clip-rule="evenodd" d="M12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L13.7071 19.7071C13.3166 20.0976 12.6834 20.0976 12.2929 19.7071C11.9024 19.3166 11.9024 18.6834 12.2929 18.2929L17.5858 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H17.5858L12.2929 5.70711C11.9024 5.31658 11.9024 4.68342 12.2929 4.29289Z" fill="#fff"/>
    </svg>
  </div>
);
export default Social;
