
import { useEffect, useState } from "react"

import { useParams } from "react-router-dom";
import { getTemplateData } from "../utils/constants";
import useLanguage from "../utils/useLanguage";
import ViewsRouter from "./viewsRouter";
import { getDomain } from "../utils/utils";


export default function LiveController({postData=null,defaultType}){
    const params = useParams();
    const t = useLanguage();
    const TEMPLATE_DATA = getTemplateData(t);
    const [sseData,setSseData] = useState(null);

    const catchData = (e)=>{
        if(e.data?.live){
            setSseData(e.data?.live ? e.data : null);
        }
    }

    const getQrData = ()=>{
        const d = getDomain(params?.domain);

        if(sseData?.live && !sseData?.static){
            return sseData 
        }else{
            try{
                return TEMPLATE_DATA[d][sseData?.type || defaultType];
            }catch(e){
                return {};
            }
        }
    }

    useEffect(()=>{
        if(postData){
            setSseData(postData);
        }else{
            window.addEventListener("message",catchData,false);
        }
        return ()=>{
            if(!postData){window.removeEventListener('message',catchData)}
        }
    },[postData])

    return <ViewsRouter domain={params?.domain} type={sseData?.type || defaultType} data={getQrData()} />

}