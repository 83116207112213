import axios from "axios";

export default function SlackAlert(domain,uid,errCode,reason){

    axios
    .get(`${domain}api/qr-code?uId=${uid}`)
    .then((result) => {
        if(result.status !== 200){
            message();
        }
    })
    .catch((err) => {
        message();
    });

    const message = (err, raw) => {
        fetch(process.env.REACT_APP_WEB_HOOK_URL,{
            method:"POST",
            headers: {
                "Accept": "*/*",
            },
            body:JSON.stringify({
                "blocks": [
                    {
                        "type": "header",
                        "text": {
                        "type": "plain_text",
                        "text": ":warning: :rotating_light: A critical priority issue has been identified.",
                        "emoji": true
                        }
                    },
                    {
                        "type": "section",
                        "text": {
                        "type": "mrkdwn",
                        "text": `QR Code Scanned Error -  <${window.location.href} |View QR>`
                        }
                    },
                    {
                        "type": "section",
                        "fields": [
                            {
                                "type": "mrkdwn",
                                "text": `*Error Code:*\n${errCode}`
                            },
                            {
                                "type": "mrkdwn",
                                "text": `*Reason:*\n${reason}`
                            },
                            {
                                "type": "mrkdwn",
                                "text": `*Domain:*\n${domain}`
                            }
                        ]
                    },
                    {
                        "type": "section",
                        "text": {
                        "type": "mrkdwn",
                        "text": "Action Required: Immediate attention and resolution are imperative."
                        }
                    }

                ]
            }),
        }).catch((err)=>{console.log(err);})
    }
  
}