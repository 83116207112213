import { useCallback, useEffect, useRef } from "react";
import { envEnums } from "../enums/enums";
import QccDefaultPage from "../components/Views/Common/DefaultPage/qccDefaultPage";
import SPDefaultPage from "../components/Views/Common/DefaultPage/spDefaultPage";
import OqgDeactivate from "../components/Views/Common/Deactivate/OqgDeactivate";
import QciDeactivate from "../components/Views/Common/Deactivate/QciDeactivate";

/**
 *
 * @return {import("react").MutableRefObject}
 */
export function useAdjustZoom() {
    const element = useRef();

    const setRef = (e) => {
        element.current = e;
    };

    const measure = useCallback(() => {
        if (element.current) {
            const zoomRatio = (window.innerWidth / 269).toFixed(2);
            element.current.style.zoom = zoomRatio;
        }
    }, [element]);

    useEffect(() => {
        measure();
        window.addEventListener("resize", measure);
        return () => window.removeEventListener("resize", measure);
    }, [element, measure]);

    return setRef;
}

/**
 * Get first subdomain from origin url
 * @param {string} url
 * @param {Array} subDomains
 * @returns {string}
 */
export function getSubdomain(url, subDomains) {
    let host = new URL(url);
    const subdomain = host.host.split(".")[0];
    return subDomains.includes(subdomain) ? `${subdomain}.` : "";
}

/**
 * Get current environment
 * @returns {string}
 */
export function getRoutePath(pPath = false) {
    if (pPath) {
        return envEnums.IS_SCANNED_PAGE ? "/:path/:uid" : "/:domain/:path/:uid";
    } else {
        return envEnums.IS_SCANNED_PAGE ? "/:uid" : "/:domain/:uid";
    }
}

export function getDefaultPage() {
    return envEnums.IS_SCANNED_PAGE ? <SPDefaultPage /> : <QccDefaultPage />
}

export function getDeactivatePage() {
    return envEnums.IS_SCANNED_PAGE ? <OqgDeactivate /> : <QciDeactivate />
}

export function getDomain(domain){
    if(domain && domain !== 'p'){
        return domain;
    }else{
        return 'q';
    }
}

export function AutoNavigateToUrl({data,type}){
    
    if(type === 'url'){
        const url = data?.url.startsWith("https://") ? data?.url  : `https://${data?.url}`;
        const decodedUrl = url.replace(/&amp;/g, "&");
        window.open(decodedUrl, "_self");
    }else if(type === 'whatsapp'){
        window.open(`https://wa.me/${data.dial_code+data?.whatsapp_number.replace(/\s/g, '')}/?text=${encodeURI(data?.whatsapp_body)}`, "_self");
    }else if(type === 'instagram'){
        const instagramUrl = `https://instagram.com/${data.instagram_username}`;
        window.open(instagramUrl, "_self");
    }    
}

export function convertImageToBase64(qrContentData, callback) {

    if(qrContentData?.vcfImage){
        if (callback) return callback(qrContentData?.vcfImage);
    }else{
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.height = 512;
        canvas.width = 512;

        ctx.beginPath();
        ctx.rect(0, 0, 1024, 1024);
        ctx.fillStyle = qrContentData?.primaryColor
        ctx.fill();

        ctx.fillStyle = 'black';
        ctx.font = `220px ${qrContentData?.font_title}`
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.fillText(`${qrContentData?.vcard_first_name ? qrContentData?.vcard_first_name[0] : ''}${qrContentData?.vcard_last_name ? qrContentData?.vcard_last_name[0] : ''}`, 256, 270);

        const dataUrl = canvas.toDataURL('image/jpeg',0.8);
        
        if(callback) callback(dataUrl);
    }
}
