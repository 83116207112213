import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// import i18n from 'i18next';

function useLanguage(){
    const location = useLocation();
    const urlParams = useMemo(()=> {return new URLSearchParams(location.search)},[location.search]);
    const lang = urlParams.get('lang');
  
    const { t, i18n } = useTranslation();
  
    useEffect(() => {
      // initialize i18next with catalog and language to use
      if (urlParams.get('lang')) {
        i18n.changeLanguage(urlParams.get('lang'));
      } else {
        i18n.changeLanguage(getLanguageName(navigator.language));
      }

      const htmlLang = document.documentElement.lang;

      if(lang === 'arabic'){
        document.dir = 'rtl';
      }else{
        document.dir = 'ltr';
      }

      const pElements = document.querySelectorAll("p");
      if(htmlLang != 'en'){
        pElements.forEach((pElement)=>{
          pElement.classList.add("not-lang");
        });
      }else{
        pElements.forEach((pElement)=>{
          pElement.classList.remove("not-lang");
        });
      }
      
    }, [i18n, urlParams ,lang]);

    return t;
}

function getLanguageName(code) {
    const languageCode = code.split('-')[0];
    switch (languageCode) {
      case 'ar':
        return 'arabic';
      
      case 'bg':
        return 'bulgarian';

      case 'hr':
        return 'croatian';

      case 'cs':
        return 'czech';

      case 'da':
        return 'danish';

      case 'nl':
        return 'dutch';

      case 'en':
        return 'english';

      case 'fi':
        return 'finnish';

      case 'fr':
        return 'french';

      case 'de':
        return 'german';

      case 'el':
        return 'greek';

      case 'hu':
        return 'hungarian';

      case 'id':
        return 'indonesian';

      case 'it':
        return 'italian';

      case 'ja':
        return 'japanese';

      case 'ko':
        return 'korean';

      case 'ms':
        return 'malay';

      case 'no':
        return 'norwegian';

      case 'pl':
        return 'polish';

      case 'pt':
        return 'portuguese';

      case 'ro':
        return 'romanian';

      case 'sr':
        return 'serbian';

      case 'sk':
        return 'slovak';

      case 'sl':
        return 'slovenian';

      case 'es':
        return 'spanish';

      case 'sv':
        return 'swedish';

      case 'th':
        return 'thai';

      case 'tr':
        return 'turkish';

      case 'uk':
        return 'ukrainian';

      case 'yo':
        return 'yoruba';

      case 'he':
        return 'hebrew';

      case 'zh-hk':
        return 'cantonese';

      case 'zh-cn':
        return 'chinese';

      case 'zh-cn':
        return 'chinese';

      case 'hi':
        return 'hindi';

      case 'pt-br':
        return 'portuguese_br';
      
      case 'tk':
        return 'turkmen';

      case 'vi':
        return 'vietnamese';

      case 'bs':
        return 'bosnian';

      case 'be':
        return 'belarusian';

      case 'az':
        return 'azerbaijani';

      case 'hy':
        return 'armenian';

      case 'af':
        return 'afrikaans';

      case 'zu':
        return 'zulu';

      case 'ru':
        return 'russian';

      case 'sq':
        return 'albanian';

      case 'et':
        return 'estonian';

      case 'ka':
        return 'georgian';

      case 'lv':
        return 'latvian';
        
      case 'lt':
        return 'lithuanian';

      case 'mt':
        return 'maltese';

      case 'lb':
        return 'Luxembourgish';

      case 'mk':
        return 'macedonian';

      case 'fil':
        return 'filipino';

      default:
        console.error('Language code not define');
        return 'english';
      
    }
}


export default useLanguage;