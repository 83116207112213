
import useLanguage from "../../../../utils/useLanguage";
import { useEffect} from "react";
import i18n from 'i18next';
import useUpdateDomTitle from "../../../../utils/updateDomTitle";

export default function QccDefaultPage(){
    const t = useLanguage();

    const currentLanguage = i18n.language;

    useEffect(() => {

        if(currentLanguage === 'arabic'){
            document.dir = 'rtl';
        }else{
            document.dir = 'ltr';
        }
        
    }, [currentLanguage]);
    
    useUpdateDomTitle(t('qrc_io.defaultPage.title'));

    return (
        <div className="defualt-page 3xl:max-w-[1536px] 3xl:mx-auto mx-[25px] tablet:mx-[50px] xl:mx-[200px] tablet:min-h-screen tablet:max-h-screen my-[25px] tablet:my-[0px] gap-2 tablet:flex tablet:justify-center tablet:flex-col">
            <div className="my-auto">
                <div className="flex-row items-center justify-evenly gap-[16px] tablet:gap-[7%] border-b-2 border-black pb-16 section grid grid-cols-1 tablet:grid-cols-2">
                    <div className="flex flex-col items-start left-side">
                        <img 
                            src="/images/qci/logo.webp" 
                            alt="logo" 
                            loading="lazy"
                            className="w-80 pb-4"
                        />
                        <p className="text-[40px] font-bold pb-4 px-2 title pl-0">{ t('qrc_io.defaultPage.title') }</p>
                        <p className="text-xl text-left font-medium px-2 para rtl:text-right pl-0">
                            { t('qrc_io.defaultPage.para_1') }
                        </p>
                        <p className="text-lg text-left font-medium pt-4 px-2 para rtl:text-right pl-0">
                            { t('qrc_io.defaultPage.para_2') }
                        </p>
                        <div className="w-full flex justify-start lg:justify-center tablet:justify-start">
                            <a 
                                className="px-[60px] py-[20px] bg-[#14a0ee] hover:bg-[#24b0fe] rounded-md text-white font-semibold text-xl flex flex-row items-center mt-5 create-btn justify-center"
                                href="https://qr-code.io/"
                            >
                                <img src="/images/qr-code.svg" alt="qr-code" className="pr-2 rtl:pl-2 rtl:pr-0"/>
                                <span>{ t('qrc_io.defaultPage.create_qr_btn') }</span>
                            </a>
                        </div>
                        
                    </div>
                    <div className="mt-[30px] tablet:mt-0 flex flex-col w-[100%] p-[20px] xs:px-[15px] sm:px-[38px] bg-no-repeat xs:h-max sm:h-[100%] min-h-none h-full tablet:max-h-[616px] 3xl:max-h-[700px] overflow-y-clip bg-[#d5ebf9] overflow-hidden rounded-[8px]">
                        <h1 
                            className="mt-[10px] xs:text-[25px] sm:text-[35px] tablet:mt-0 text-center leading-[1.2] tablet:text-left xl:text-[40px] text-[34px] font-semibold text-[#14a0ee] rtl:text-right 3xl:w-[500px]"
                            style={{
                                WebkitTextStroke:'#14a0ee',
                                WebkitTextFillColor:'#14a0ee'
                            }}
                        >
                            { t('qrc_io.defaultPage.image_title') }
                        </h1>
                        <div className="mt-0 tablet:mt-[30px] xl:mt-[0px] w-[100%] tablet:w-[480px] lg:w-[580px] 3xl:w-[700px] min-w-[100%] min-h-none tablet:min-h-[584px] relative ">
                            <img
                                className="w-[90%] tablet:w-[100%] m-auto 2xl:absolute 2xl:right-[-30px]" 
                                src="/images/qci/qci-default-img.webp" 
                                alt="qr-cards"
                                style={{filter: 'drop-shadow(4px 2px 11px #696b6d5e)'}}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center sm:justify-center justify-between px-2 mt-4 space-x-5  sm:space-x-10  font-medium text-lg df-section">
                    <a href="mailto:support@qr-code.io?subject=Report%20abuse">{ t('qrc_io.defaultPage.report_abuse') }</a>
                    <a href="https://qr-code.io/contact" target="_blank" rel="noopener noreferrer">{ t('qrc_io.defaultPage.contact_us') }</a>
                    <a href="https://qr-code.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">{ t('qrc_io.defaultPage.terms_text') }</a>
                    <a href="https://qr-code.io/privacy-policy" target="_blank" rel="noopener noreferrer">{ t('qrc_io.defaultPage.privacy_text') }</a>
                </div>
                <div className="flex flex-row items-center justify-between px-2 df-footer pt-[1.5rem] pb-0">
                    <div className="font-medium">{ t('qrc_io.defaultPage.footer_text_1') }</div>
                    <div className="font-medium">{ t('qrc_io.defaultPage.footer_text_2') }</div>
                </div>
            </div>
        </div>
    )
}