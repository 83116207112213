// Common View Components
import Images from "./Views/Common/Images";
import Video from "./Views/Common/Video";
import Menu from "./Views/Common/Menu";
import Mp3 from "./Views/Common/MP3";
import Coupon from "./Views/Common/Coupon";
import Whatsapp from "./Views/Common/Whatsapp";
import Event from "./Views/Common/Event";
import Email from "./Views/Common/Email";
import Review from "./Views/Common/Review";
import Social from "./Views/Common/Social";
import Facebook from "./Views/Common/Facebook";
import Instagram from "./Views/Common/Instagram";
import Sms from './Views/Common/SMS';
import Apps from "./Views/Common/Apps";

// qr-code-generator.com View Components
import WebsiteOqg from "./Views/Oqg/Website";
import PDFOqg from "./Views/Oqg/PDF";
import LinksOqg from "./Views/Oqg/ListOfLinks";
import BusinessOqg from "./Views/Oqg/Business";
import MP3Oqg from "./Views/Oqg/MP3";
import VCardOqg from "./Views/Oqg/Vcard";
import WIFIOqg from "./Views/Oqg/Wifi";
import InstagramOqg from "./Views/Oqg/Instagram";
import WhatsappOqg from "./Views/Oqg/Whatsapp";

// qr-code.ai View Components
import WebsiteQai from "./Views/Qai/Website";
import WifiQai from "./Views/Qai/Wifi";
import WhatsAppQai from "./Views/Qai/Whatsapp";

// qr.box view Components
import WebsiteQrb from "./Views/Qrb/Website";
import WifiQrb from "./Views/Qrb/Wifi";

// qr-code.io View Components
import BusinessQci from "./Views/Qci/Business";
import WebsiteQci from "./Views/Qci/Website";
import PDFQci from "./Views/Qci/PDF";
import ListOfLinksQci from "./Views/Qci/ListOfLinks";
import VcardQci from "./Views/Qci/Vcard";
import WifiQci from "./Views/Qci/Wifi";
import InstagramQci from "./Views/Qci/Instagram";
import WhatsappQci from "./Views/Qci/Whatsapp";
import DefaultQci from "./Preview/Step01/DefaultQci";

import { AutoNavigateToUrl, getDomain } from '../utils/utils';
import Default from "./Preview/Step01/Default";
import FacebookOqg from "./Views/Oqg/Facebook";
import FacebookQci from "./Views/Qci/Facebook";

export default function ViewsRouter({domain,type,data}){

    /*
        q = oqg
        i = qci
        b = qrb
        a = qai
    */

    const QR_TYPE_VIEWS = {
        i:{
            url:<AutoNavigateToUrl data={data} type={type} />,
            website:<WebsiteQci url={data?.url} />,
            wifi:<WifiQci qrData={data?.networkName} />,
            pdf:<PDFQci qrData={data} />,
            links:<ListOfLinksQci qrData={data} />,
            business:<BusinessQci qrData={data} />,
            vcard:<VcardQci qrData={data} />,
            images:<Images qrData={data} />,
            video:<Video qrData={data} />,
            app:<Apps qrData={data} />,
            coupon:<Coupon qrData={data} />,
            mp3:<Mp3 qrData={data} />,
            menu:<Menu qrData={data} />,
            whatsapp: data?.step == 1 ? <WhatsappQci/> : data?.live ? <Whatsapp qrData={data}/> :  <AutoNavigateToUrl data={data} type={type} />,
            event:<Event qrData={data} />,
            email:<Email qrData={data} />,
            review:<Review qrData={data} />,
            social:<Social qrData={data} />,
            facebook:<FacebookQci qrData={data} />,
            instagram: data?.step == 1 ? <InstagramQci/> : data?.live ? <Instagram qrData={data} /> :  <AutoNavigateToUrl data={data} type={type} />,
            sms:<Sms qrData={data} />,
            default:<DefaultQci />
        },
        q:{
            url:<AutoNavigateToUrl data={data} type={type} />,
            website:<WebsiteOqg url={data?.url} />,
            wifi:<WIFIOqg qrData={data?.networkName} />,
            images:<Images qrData={data} />,
            video:<Video qrData={data} />,
            app:<Apps qrData={data} />,
            coupon:<Coupon qrData={data} />,
            pdf:<PDFOqg qrData={data}/>,
            mp3:<MP3Oqg qrData={data}/>,
            menu:<Menu qrData={data} />,
            vcard:<VCardOqg qrData={data}/>,
            links:<LinksOqg qrData={data}/>,
            business:<BusinessOqg qrData={data}/>,
            whatsapp: data?.step == 1 ? <WhatsappOqg/> : data?.live ? <Whatsapp qrData={data}/> :  <AutoNavigateToUrl data={data} type={type} />,
            event:<Event qrData={data} />,
            email:<Email qrData={data} />,
            review:<Review qrData={data} />,
            social:<Social qrData={data} />,
            facebook:<FacebookOqg qrData={data} />,
            instagram: data?.step == 1 ? <InstagramOqg/> : data?.live ? <Instagram qrData={data} /> :  <AutoNavigateToUrl data={data} type={type} />,
            sms:<Sms qrData={data} />,
            default:<Default />
        },
        a:{
            url:<AutoNavigateToUrl data={data} type={type} />,
            website:<WebsiteQai url={data?.url} />,
            wifi:<WifiQai qrData={data?.networkName} />,
            pdf:<PDFQci qrData={data} />,
            links:<ListOfLinksQci qrData={data} />,
            vcard:<VcardQci qrData={data} />,
            business:<BusinessQci qrData={data} />,
            images:<Images qrData={data} />,
            video:<Video qrData={data} />,
            app:<Apps qrData={data} />,
            coupon:<Coupon qrData={data} />,
            mp3:<Mp3 qrData={data} />,
            menu:<Menu qrData={data} />,
            whatsapp: data?.live ? <Whatsapp qrData={data}/> :  <AutoNavigateToUrl data={data} type={type} />,
            event:<Event qrData={data} />,
            email:<Email qrData={data} />,
            review:<Review qrData={data} />,
            social:<Social qrData={data} />,
            facebook:<Facebook qrData={data} />,
            instagram: data?.live ? <Instagram qrData={data} /> :  <AutoNavigateToUrl data={data} type={type} />,
            sms:<Sms qrData={data} />,
            default:<DefaultQci />
        },
        b:{
            url:<AutoNavigateToUrl data={data} type={type} />,
            website:<WebsiteQrb url={data?.url} />,
            wifi:<WifiQrb qrData={data?.networkName} />,
            pdf:<PDFQci qrData={data} />,
            links:<ListOfLinksQci qrData={data} />,
            vcard:<VcardQci qrData={data} />,
            business:<BusinessQci qrData={data} />,
            images:<Images qrData={data} />,
            video:<Video qrData={data} />,
            app:<Apps qrData={data} />,
            coupon:<Coupon qrData={data} />,
            mp3:<Mp3 qrData={data} />,
            menu:<Menu qrData={data} />,
            whatsapp: data?.live ? <Whatsapp qrData={data}/> :  <AutoNavigateToUrl data={data} type={type} />,
            event:<Event qrData={data} />,
            email:<Email qrData={data} />,
            review:<Review qrData={data} />,
            social:<Social qrData={data} />,
            facebook:<Facebook qrData={data} />,
            iinstagram: data?.live ? <Instagram qrData={data} /> :  <AutoNavigateToUrl data={data} type={type} />,
            sms:<Sms qrData={data} />,
            default:<DefaultQci />
        }
    }

    try{
        return QR_TYPE_VIEWS[getDomain(domain)][type];
    }catch(e){
        return QR_TYPE_VIEWS[getDomain(domain)].default;
    }
}
