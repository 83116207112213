import {React} from "react";
import "./index.css";
// import useLanguage from "../../../utils/useLanguage";
import useUpdateDomTitle from "../../../utils/updateDomTitle";

let SMSPreview = (props) => {

    useUpdateDomTitle('Online QR Generator - Scanned Page | SMS',"#3DC92C");
    return (
        <div className="flex flex-col justify-center items-center" >
            <div className="flex flex-col items-center justify-center w-full max-w-[700px] min-h-screen h-screen ">
                <div className="flex items-end justify-between w-full px-[15px] bg-[#F1F1F2] pb-2">
                    <div className="flex flex-row items-center justify-between  w-full mt-10">
                        <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.59766 20.3906C9.86719 20.6484 10.1953 20.7891 10.5703 20.7891C11.3555 20.7891 11.9648 20.1797 11.9648 19.4062C11.9648 19.0195 11.8125 18.668 11.543 18.3984L3.33984 10.3828L11.543 2.39062C11.8125 2.12109 11.9648 1.75781 11.9648 1.38281C11.9648 0.609375 11.3555 0 10.5703 0C10.1953 0 9.86719 0.140625 9.60938 0.398438L0.492188 9.30469C0.164062 9.60938 0 9.98438 0 10.3945C0 10.8047 0.164062 11.1562 0.480469 11.4727L9.59766 20.3906Z" fill="#3478F6"/>
                        </svg>

                        <div className="flex flex-col items-center justify-center">
                            <svg width="49" height="48" className="w-10 xsm:w-12 drop-shadow-[0px_6px_4px_rgba(0,0,0,0.25)] mb-2" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.25" width="48" height="48" rx="24" fill="white"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M24.139 24.6185H24.171C27.099 24.6185 29.48 22.2375 29.48 19.3095C29.48 16.3815 27.099 13.9995 24.171 13.9995C21.242 13.9995 18.86 16.3815 18.86 19.3065C18.85 22.2265 21.217 24.6095 24.139 24.6185ZM20.288 19.3095C20.288 17.1685 22.03 15.4275 24.171 15.4275C26.311 15.4275 28.052 17.1685 28.052 19.3095C28.052 21.4495 26.311 23.1915 24.171 23.1915H24.142C22.01 23.1835 20.281 21.4435 20.288 19.3095Z" fill="#CECECE"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M16.25 30.1728C16.25 33.8699 22.212 33.8699 24.171 33.8699C27.57 33.8699 32.09 33.4888 32.09 30.1929C32.09 26.4958 26.13 26.4958 24.171 26.4958C20.771 26.4958 16.25 26.8769 16.25 30.1728ZM17.75 30.1728C17.75 28.7278 19.91 27.9958 24.171 27.9958C28.431 27.9958 30.59 28.7348 30.59 30.1929C30.59 31.6378 28.431 32.3699 24.171 32.3699C19.91 32.3699 17.75 31.6308 17.75 30.1728Z" fill="#CECECE"/>
                            </svg>
                            <div className="w-[60px] h-[7px] bg-[#DBDBDB] rounded-full"></div>
                        </div>

                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.75" y="5.25" width="17.5" height="15.5" rx="3.25" stroke="#3478F6" strokeWidth="1.5"/>
                            <path d="M18.6001 13.6684C18.2588 13.2883 18.2588 12.7121 18.6001 12.332L23.256 7.14784C23.8687 6.4656 25 6.89903 25 7.81603L25 18.1844C25 19.1014 23.8687 19.5348 23.256 18.8526L18.6001 13.6684Z" stroke="#3478F6" strokeWidth="1.5"/>
                        </svg>

                    </div>
                </div>
                <div className="flex-1 w-full relative">
                    <div className="w-full flex flex-col mt-3 space-y-3 xsm:space-y-5">
                        <div className="w-full flex justify-end pr-5">
                            <div className="bg-[#3DC92C] px-3 py-2 w-4/5 rounded-2xl relative">
                                <p className="text-white text-xs xsm:text-[14px] sm:text-[15px] text-start sfPro">Hey, just scanned the QR code at your new cafe. Are you open on Sundays?</p>
                                <svg className="absolute right-[-5px] bottom-0" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 10.5C12.0014 13.5086 14.8333 16.3333 16.5 17C10.1 17 6 14.8333 5 13.5L0 15L0.5 0H11V2V4V4.5C11 5.5 11 7.5 11.5 10.5Z" fill="#3DC92C"/>
                                </svg>
                            </div>
                        </div>

                        <div className="w-full flex justify-start pl-5">
                            <div className="bg-[#E9E9EB] px-3 py-2 w-4/5 rounded-2xl relative">
                                <p className="text-black text-xs xsm:text-[14px] sm:text-[15px] text-start sfPro">Hi there! Yes, we are open on Sundays from 8 AM to 8 PM. Looking forward to serving you. <img src="images/images/new/smiling-emoji.png" className="w-4 inline" alt="" /></p>
                                
                                <svg className="absolute left-[-5px] bottom-0" style={{transform: "scale(-1, 1)"}} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 10.5C12.0014 13.5086 14.8333 16.3333 16.5 17C10.1 17 6 14.8333 5 13.5L0 15L0.5 0H11V2V4V4.5C11 5.5 11 7.5 11.5 10.5Z" fill="#E9E9EB"/>
                                </svg>
                            </div>
                        </div>

                        <div className="w-full flex justify-end pr-5">
                            <div className="bg-[#3DC92C] px-3 py-2 w-4/5 rounded-2xl relative">
                                <p className="text-white text-xs xsm:text-[14px] sm:text-[15px] text-start sfPro">Awesome! Do I need to book a table for brunch?</p>
                                <svg className="absolute right-[-5px] bottom-0" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 10.5C12.0014 13.5086 14.8333 16.3333 16.5 17C10.1 17 6 14.8333 5 13.5L0 15L0.5 0H11V2V4V4.5C11 5.5 11 7.5 11.5 10.5Z" fill="#3DC92C"/>
                                </svg>
                            </div>
                        </div>

                        <div className="w-full flex justify-start pl-5">
                            <div className="bg-[#E9E9EB] px-3 py-2 w-4/5 rounded-2xl relative">
                                <p className="text-black text-xs xsm:text-[14px] sm:text-[15px] text-start sfPro">For weekends, booking is recommended but not necessary. You can book a table by replying with your preferred time and the number of people.</p>
                                <svg className="absolute left-[-5px] bottom-0" style={{transform: "scale(-1, 1)"}} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 10.5C12.0014 13.5086 14.8333 16.3333 16.5 17C10.1 17 6 14.8333 5 13.5L0 15L0.5 0H11V2V4V4.5C11 5.5 11 7.5 11.5 10.5Z" fill="#E9E9EB"/>
                                </svg>
                            </div>
                        </div>

                        <div className="w-full flex justify-end pr-5">
                            <div className="bg-[#3DC92C] px-3 py-2 w-4/5 rounded-2xl relative">
                                <p className="text-white text-xs xsm:text-[14px] sm:text-[15px] text-start sfPro">Great! We will book a table for two at 10 AM this Sunday, please</p>
                                <svg className="absolute right-[-5px] bottom-0" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 10.5C12.0014 13.5086 14.8333 16.3333 16.5 17C10.1 17 6 14.8333 5 13.5L0 15L0.5 0H11V2V4V4.5C11 5.5 11 7.5 11.5 10.5Z" fill="#3DC92C"/>
                                </svg>
                            </div>
                        </div>

                        <div className="w-full flex justify-start pl-5">
                            <div className="bg-[#E9E9EB] px-3 py-2 w-4/5 rounded-2xl relative">
                                <p className="text-black text-xs xsm:text-[14px] sm:text-[15px] text-start sfPro">Your table for two at 10 AM this Sunday is confirmed. We're excited to welcome you to Cafe Delight! <img src="images/images/new/party-emoji.png" className="w-4 inline" alt="" /></p>
                                <svg className="absolute left-[-5px] bottom-0" style={{transform: "scale(-1, 1)"}} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5 10.5C12.0014 13.5086 14.8333 16.3333 16.5 17C10.1 17 6 14.8333 5 13.5L0 15L0.5 0H11V2V4V4.5C11 5.5 11 7.5 11.5 10.5Z" fill="#E9E9EB"/>
                                </svg>
                            </div>
                        </div>

                    </div>

                    <div className="flex space-x-3 w-full px-[15px] absolute bottom-6">
                        <div className="bg-[#E8E9EB] w-[34px] h-[34px] rounded-full flex items-center justify-center">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.25 8.05469C0.875 8.05469 0.554688 7.74219 0.554688 7.35938C0.554688 6.97656 0.875 6.65625 1.25 6.65625H6.30469V1.60938C6.30469 1.23438 6.61719 0.914062 7 0.914062C7.38281 0.914062 7.70312 1.23438 7.70312 1.60938V6.65625H12.75C13.125 6.65625 13.4453 6.97656 13.4453 7.35938C13.4453 7.74219 13.125 8.05469 12.75 8.05469H7.70312V13.1094C7.70312 13.4844 7.38281 13.8047 7 13.8047C6.61719 13.8047 6.30469 13.4844 6.30469 13.1094V8.05469H1.25Z" fill="#7D7F85"/>
                            </svg>
                        </div>

                        <div className="bg-white flex-1 rounded-full flex items-center justify-between py-2 px-3  border-[1px] border-[#B4B8BF]">
                            <p className="text-sm text-[#C5C5C7] sfPro">iMessage</p>
                            <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 11.4062C4.4375 11.4062 3.39062 10.2422 3.39062 8.57812V3.35156C3.39062 1.67969 4.4375 0.523438 6 0.523438C7.55469 0.523438 8.60156 1.67969 8.60156 3.35156V8.57812C8.60156 10.2422 7.55469 11.4062 6 11.4062ZM0.40625 8.70312V7.11719C0.40625 6.78906 0.664062 6.53125 0.992188 6.53125C1.32812 6.53125 1.58594 6.78906 1.58594 7.11719V8.65625C1.58594 11.3203 3.32031 13.0859 6 13.0859C8.67188 13.0859 10.4062 11.3203 10.4062 8.65625V7.11719C10.4062 6.78906 10.6719 6.53125 11 6.53125C11.3281 6.53125 11.5859 6.78906 11.5859 7.11719V8.70312C11.5859 11.7656 9.57031 13.9141 6.58594 14.1641V15.9844H9.48438C9.8125 15.9844 10.0781 16.25 10.0781 16.5781C10.0781 16.9062 9.8125 17.1641 9.48438 17.1641H2.50781C2.17969 17.1641 1.91406 16.9062 1.91406 16.5781C1.91406 16.25 2.17969 15.9844 2.50781 15.9844H5.40625V14.1641C2.42969 13.9141 0.40625 11.7656 0.40625 8.70312Z" fill="#B4B8BF"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
};

export default SMSPreview;
